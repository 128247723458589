import React from "react";
import Calender from "../ServiceRequest/Calender";
import ContentHeader from "../../components/common/ContentHeader";
import MainTitle from "../../components/common/MainTitle";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

const AdjustmentManagement = (props) => {
  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Adjustment Management" />
        <Row>
          <Col lg="12" className="mb-4">
            <ContentHeader>
              <Button
                onClick={() => {
                  props.history.push("");
                }}
                outline
                theme="primary"
                className="mb-2 mr-2"
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  props.history.push("/warranty-dispute-mgt");
                }}
                outline
                theme="primary"
                className="mb-2 ml-2 mr-2"
              >
                Cancel
              </Button>
            </ContentHeader>
            <ListGroupItem>
              <Row>
              <Col md={{ size: 2, order: 1 }}>
                  <label htmlFor="feLastName">User ID</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                  />
                </Col>
              <Col md={{ size: 2, order: 2 }}>
                  <label>User Type</label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 2, order: 3 }}>
                <label>Adjust Amount Range</label>
                <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Start Amount $"
                    name="customerId"
                  />
                  
                   <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="End Amount $"
                    name="customerId"
                  />
                </Col>
                <Col md={{ size: 3, order: 4}}>
                  <label>Payment Method Type</label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label>Manual Adjustment Type</label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
               
                <Col md={{ size: 10, order: 6 }} className="form-group p-3">
                  <label>Adjustment Date</label>
                  <Calender />
                </Col>
                <Col md={{ size: 2, order: 7 }} className="form-group p-3">
                  <Button outline theme="primary" className="mt -4 mb-2 ml-4">
                    Apply Filter
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
      <Container fluid className="main-content-container px-4">
        <Row>
          <Col lg="12" md={{ size: 4, order: 4 }} className="mb-6">
            <h4>
            
              <b>Adjustment List </b>
            </h4>
          </Col>
          <Col lg="12" className="mb-6"></Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <MaterialTable
                  title=""
                  columns={[
                    { title: "ID", field: "id" },
                    { title: "Invoice Amount", field: "srId" },
                    {
                      title: "SP PayOut Amount",
                      field: "srCreationDate",
                    },
                    { title: "eZunction Payout Amount", field: "srScheduledDate" },
                    { title: "Transaction ID (From Txn Table)", field: "srScheduledDate" },
                    { title: "Transaction Date (From Txn Table)", field: "srScheduledDate" },
                    { title: "Manual Adjustment Type (Refund/Charge)", field: "srScheduledDate" },
                    { title: "Adjustment Amount", field: "user.first_name" },
                    { title: "Adjustment Reason", field: "user.last_name" },
                    { title: "Adj for Request ID", field: "user.last_name" },
                    { title: "Adjustment for Quote ID", field: "user.last_name" },
                    { title: "Adjustment Issued to User ID", field: "user.last_name" },
                    {title: "User Type",field: "user.last_name"},
                    { title: "Adjustment Date", field: "user.last_name" },
                    { title: "Payment Method ID", field: "user.last_name" },
                    { title: "Payment Method Type (Card/Bank Account)", field: "user.last_name" }  
                  ]}
                  data={[
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                  ]}
                  options={{
                    search: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportCsv: (columns, data) => {},
                  }}
                  actions={[
                    {
                      icon: "check",
                      tooltip: "Coupon Details",
                    },

                    {
                      icon: "edit",
                      tooltip: "Edit Request",
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push("/edit-quote");
                      },
                    },

                    {
                      icon: "delete",
                      tooltip: "Delete Request",
                    },
                  ]}
                />
                <Col  className="text-md-right mb-2 mt-4 mr-5"
              
               >
                 <Button
                onClick={() => {
                  props.history.push("/create-adjustment");
                }}
                outline
                theme="primary"
              >
                Create Adjustment
              </Button>
              </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default AdjustmentManagement;
