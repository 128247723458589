import config from './../data/config';
import base64 from 'base-64';

export function authHeader(email = '', password = '') {
	// return authorization header with basic auth credentials
	if (email && password) {
		let basicAuthString = base64.encode(email + ':' + password);
		// console.log(basicAuthString);
		return {
			'Content-Type': config.contentType,
			api_key: config.apiKey,
			Authorization: 'Basic ' + basicAuthString
		};
	} else {
		let user = JSON.parse(localStorage.getItem('user'));

		if (user && user.auth_data) {
			return {
				'Content-Type': config.contentType,
				api_key: config.apiKey,
				Authorization: 'Basic ' + user.auth_data
			};
		} else {
			return {};
		}
	}
}
