import React from "react";
import ContentHeader from "../../components/common/ContentHeader";
import MainTitle from "../../components/common/MainTitle";
import MaterialTable from "material-table";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CustBottom from "../ServiceRequest/CustButtom";

import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormRadio,
  FormTextarea,
} from "shards-react";

const ViewServiceRequest = (props) => {
  function RadioButton() {
    const [value, setValue] = React.useState("home");

    const handleChange = (event) => {
      setValue(event.target.value);
    };

    return (
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="address"
          name="adress"
          value={value}
          onChange={handleChange}
          row
        >
          <FormControlLabel
            value="home"
            control={<Radio color="primary" />}
            label="Home Address"
          />
          <FormControlLabel
            value="office"
            control={<Radio color="primary" />}
            label="Other Address"
          />
        </RadioGroup>
      </FormControl>
    );
  }

  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="View Service Request Details" />
        <Row>
          <Col lg="12" className="mb-4">
            <ContentHeader>
              <Button  onClick={()=>{props.history.push('/service-request-mgt')}} outline theme="primary" className="mb-2 mr-1">
                Back
              </Button>
            </ContentHeader>

            <ListGroupItem>
              <Row>
                <Col md={{ size: 2, order: 2 }}>
                  <label>Customer ID</label>
                  <FormInput
                    id="feCustomerID"
                    placeholder="Customer ID"
                    type="text"
                    name="customerID"
                  />
                </Col>
                <Col md={{ size: 2, order: 2 }}>
                  <label>SR ID</label>
                  <FormInput
                    id="SrID"
                    placeholder="SR ID"
                    type="text"
                    name="SrID"
                  />
                </Col>
                <Col md={{ size: 3, order: 3 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                  />
                </Col>
                <Col md={{ size: 3, order: 3 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="fistName"
                  />
                </Col>

                <Col md={{ size: 2, order: 4 }}>
                  <label>SR Status</label>
                  <FormInput
                    id="SrStatus"
                    placeholder="SR Status"
                    type="text"
                    name="SrStatus"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="8" className="mb-4">
                  <Row>
                    <Col md={{ size: 8, order: 4 }}>
                      <h4>Service Address</h4>
                    </Col>
                    <Col md={{ size: 12, order: 4 }}>
                      <RadioButton />
                    </Col>

                    <Col md={{ size: 6, order: 4 }}>
                      <label htmlFor="feAddress">Service Address Line 1</label>
                      <FormInput
                        id="feAddress"
                        type="text"
                        placeholder="Service Address Line 1"
                        name="address_1"
                      />
                    </Col>
                    <Col md={{ size: 6, order: 4 }}>
                      <label htmlFor="feAddress">Service Address Line </label>
                      <FormInput
                        id="feAddress"
                        type="text"
                        placeholder="Service Address Line 2"
                        name="address_2"
                      />
                    </Col>
                    <Col md={{ size: 3, order: 4 }}>
                      <label htmlFor="feCity">City</label>
                      <FormSelect id="feCity">
                        <option>Choose</option>
                      </FormSelect>
                    </Col>
                    <Col md={{ size: 3, order: 4 }}>
                      <label htmlFor="feState">State</label>
                      <FormSelect id="feState">
                        <option>Choose</option>
                      </FormSelect>
                    </Col>
                    <Col md={{ size: 3, order: 4 }}>
                      <label htmlFor="feZip">Zip Code</label>
                      <FormInput
                        id="feZip"
                        type="text"
                        placeholder="Zip Code"
                        name="zip_code"
                      />
                    </Col>
                    <Col md={{ size: 3, order: 4 }}>
                      <label htmlFor="feCountry">Country</label>
                      <FormSelect id="feCountry">
                        <option>Choose</option>
                      </FormSelect>
                    </Col>

                    <Col md={{ size: 12, order: 4 }}>
                      <h4>Expected Date and Time</h4>
                    </Col>
                    <Col md={{ size: 6, order: 4 }}>
                      <label htmlFor="feDate">Service Date</label>
                      <FormInput
                        id="feDate"
                        type="date"
                        name="date"
                        value={"YYYY-MM-DD"}
                        required
                      />
                    </Col>
                    <Col md={{ size: 6, order: 4 }}>
                      <label htmlFor="feTime">Service Time</label>
                      <FormSelect id="feTime" name="time" required>
                        <option>Choose</option>
                      </FormSelect>
                    </Col>
                    <Col md={{ size: 12, order: 4 }}>
                      <h4>Service</h4>
                    </Col>

                    <Col md={{ size: 4, order: 4 }}>
                      <label>Service Category</label>
                      <FormSelect id="feServiceCategory">
                        <option>Choose</option>
                      </FormSelect>
                    </Col>
                    <Col md={{ size: 4, order: 4 }}>
                      <label>Service Sub-Category</label>
                      <FormSelect id="feUser">
                        <option>Choose</option>
                      </FormSelect>
                    </Col>
                    <Col md={{ size: 4, order: 4 }}>
                      <label htmlFor="feServiceType">Service Type </label>
                      <FormSelect id="feServiceType">
                        <option>Choose</option>
                      </FormSelect>
                    </Col>
                    <Col md={{ size: 12, order: 4 }}>
                      <h4>Request Title </h4>

                      <Col md={{ size: 12, order: 4 }}>
                        <FormTextarea
                          id="feTitle"
                          type="text"
                          placeholder="Provide high level summary of your request"
                          name="title"
                          value={""}
                          required
                        />
                      </Col>
                    </Col>
                    <Col md={{ size: 12, order: 4 }}>
                      <h4>Request Description</h4>
                      <Col md={{ size: 12, order: 12 }}>
                        <FormTextarea
                          id="feDescription"
                          type="text"
                          placeholder="Provide a brief description of the request and all the relevent details to get the most accurate quote"
                          name="description"
                          value={""}
                          required
                        />
                      </Col>
                    </Col>
                  </Row>
                </Col>

                <Col lg="4" className="mb-4" className="mb-2 mt-5">
                  <Row>
                    <Col md={{ size: 6, order: 1 }} className="mb-2 mt-5">
                      <h4>Uploaded Photo</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 6, order: 2 }} className="mb-2 mt-5">
                      <h4>Uploaded Videos</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 12, order: 3 }} className="mb-2 mt-5">
                      <label htmlFor="feZip">Total # of Quotes Received</label>
                      <FormInput
                        id="feZip"
                        type="text"
                        placeholder="Total # of Quotes Received"
                        name="zip_code"
                      />
                    </Col>
                    <Col md={{ size: 12, order: 4 }} className="mb-2 mb-2">
                      <label htmlFor="feZip">
                        Total # of Warranty Received
                      </label>
                      <FormInput
                        id="feZip"
                        type="text"
                        placeholder="Total # of Warranty Received"
                        name="zip_code"
                      />
                    </Col>
                    <Col md={{ size: 12, order: 5 }} className="mb-2 mb-2">
                      <label htmlFor="feZip">Total # of Dispute Received</label>
                      <FormInput
                        id="feZip"
                        type="text"
                        placeholder="Total # of Dispute Received"
                        name="zip_code"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Col sm={{ size: 6, order: 6, offset: 10 }} className="mb-5">
                <Button  onClick={()=>{props.history.push('/service-request-mgt')}} outline theme="primary">
                  Edit
                </Button>
              </Col>
            </ListGroupItem>
          </Col>
          <Container fluid className="main-content-container">
            <Row>
              <Col sm={{ size: 12, order: 2 }}>
                <CustBottom />
              </Col>
            </Row>

            <ViewQuotestList />
            <ViewWarrantyRequestList />
            <ViewDisputeRequestList />
          </Container>
        </Row>
      </Container>
    </div>
  );
};

export default ViewServiceRequest;

const ViewQuotestList = () => {
  return (
    <div>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardBody className="p-0 pb-3">
              <MaterialTable
                title=""
                columns={[
                  { title: "ID", field: "id" },
                  { title: "Quote ID", field: "quoteId" },
                  { title: "Quote Creation Date", field: "quoteCreationDate" },
                  { title: "Quote Status", field: "quoteStatus" },
                  { title: "Service Provider ID", field: "ServiceProviderID" },
                  { title: "Warranty Expiry Date", field: "user.first_name" },
                  {
                    title: "Total Quote Amount to Customer",
                    field: "user.last_name",
                  },
                  { title: "Total Quote Amount to SP", field: "user.email" },
                  { title: "Labour Quote Amount to SP", field: "user.phone" },
                  {
                    title: "Material Included(Yes/No)",
                    field: "user.user_roles.name",
                  },
                  {
                    title: "Material Quote Amount from SP",
                    field: "service_date",
                  },
                ]}
                data={[
                  {
                    name: "",
                    surname: "Baran",
                    birthYear: 1987,
                    birthCity: 63,
                  },
                  {
                    name: "",
                    surname: "Baran",
                    birthYear: 2017,
                    birthCity: 34,
                  },
                  {
                    name: "",
                    surname: "Baran",
                    birthYear: 1987,
                    birthCity: 63,
                  },
                  {
                    name: "",
                    surname: "Baran",
                    birthYear: 2017,
                    birthCity: 34,
                  },
                ]}
                options={{
                  search: true,
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportCsv: (columns, data) => {
                    alert(
                      "You should develop a code to export " +
                        data.length +
                        " rows"
                    );
                  },
                }}
                actions={[
                  {
                    icon: "chat",
                    tooltip: "View Chat",
                  },

                  {
                    icon: "comment",
                    tooltip: "View Comment",
                  },
                ]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const ViewWarrantyRequestList = () => {
  return (
    <div>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardBody className="p-0 pb-3">
              <MaterialTable
                title=""
                columns={[
                  { title: "ID", field: "id" },
                  { title: "Warranty Details", field: "srId" },
                  { title: "Warranty Creation Date", field: "srCreationDate" },
                  {
                    title: "SR ID",
                    field: "",
                  },
                  { title: "Quote Id", field: "user.first_name" },
                  { title: "Customer ID", field: "user.last_name" },
                  { title: "Service Provider ID", field: "user.email" },
                  { title: "Warranty Closure Date", field: "user.phone" },
                  {
                    title: "Warranty Expiry Date",
                    field: "user.user_roles.name",
                  },
                  { title: "Warranty Closure Comments", field: "service_date" },
                ]}
                data={[
                  {
                    name: "",
                    surname: "Baran",
                    birthYear: 1987,
                    birthCity: 63,
                  },
                  {
                    name: "",
                    surname: "Baran",
                    birthYear: 2017,
                    birthCity: 34,
                  },
                  {
                    name: "",
                    surname: "Baran",
                    birthYear: 1987,
                    birthCity: 63,
                  },
                  {
                    name: "",
                    surname: "Baran",
                    birthYear: 2017,
                    birthCity: 34,
                  },
                ]}
                options={{
                  search: true,
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportCsv: (columns, data) => {
                    alert(
                      "You should develop a code to export " +
                        data.length +
                        " rows"
                    );
                  },
                }}
                actions={[
                  {
                    icon: "chat",
                    tooltip: "View Chats",
                   
                  },
                  {
                    icon: "comment",
                    tooltip: "View Comment",
                  }
                ]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const ViewDisputeRequestList = () => {
  return (
    <div>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardBody className="p-0 pb-3">
              <MaterialTable
                title=""
                columns={[
                  { title: "ID", field: "id" },
                  { title: "Dispute Details", field: "srId" },
                  { title: "Dispute Reason", field: "srCreationDate" },
                  {
                    title: "Dispute Creation Date",
                    field: "srScheduledDate",
                  },
                  { title: "SR ID", field: "user.first_name" },
                  { title: "Quote ID", field: "user.last_name" },
                  { title: "Customer ID ", field: "user.email" },
                  { title: "Provider ID", field: "user.phone" },
                  {
                    title: "Closutre Date",
                    field: "user.user_roles.name",
                  },
                  { title: "Closure Comments", field: "service_date" },
                ]}
                data={[
                  {
                    name: "",
                    surname: "Baran",
                    birthYear: 1987,
                    birthCity: 63,
                  },
                  {
                    name: "",
                    surname: "Baran",
                    birthYear: 2017,
                    birthCity: 34,
                  },
                  {
                    name: "",
                    surname: "Baran",
                    birthYear: 1987,
                    birthCity: 63,
                  },
                  {
                    name: "",
                    surname: "Baran",
                    birthYear: 2017,
                    birthCity: 34,
                  },
                ]}
                options={{
                  search: true,
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportCsv: (columns, data) => {},
                }}
                actions={[
                  {
                    icon: "chat",
                    tooltip: "View chat",
                    onClick: (event, rowData) => {
                      this.setState({
                        redirect: true,
                        redirectPath: "/users/" + rowData.id,
                        redirectData: { data: rowData },
                      });
                    },
                  },
                  {
                    icon: "comment",
                    tooltip: "View Comment",
                  }
                ]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export { ViewQuotestList, ViewDisputeRequestList, ViewWarrantyRequestList };
