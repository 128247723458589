module.exports = {
	url: 'baseurl', // modify this setting only if explicit pointing is required.
	baseurl: 'http://3.135.192.120:3334',
	localurl: 'http://localhost:3334',
	ngrokurl: 'https://3da17821.ngrok.io',
	apiKey: '6d0db711938bea9d123a5bc8afb41acc3ee3b4cd',
	contentType: 'application/json',
	accept: 'application/json',
	endpoints: {
		login: '/api/dashboard/admins/login',
		categories: '/api/dashboard/categories/',
		subcategories: '/api/dashboard/subcategories/',
		serviceType: '/api/dashboard/service-type/',
		housetype: '/api/dashboard/housetype/',
		timeslots: '/api/dashboard/timeslots/',
		withdrawalReason: '/api/dashboard/withdrawalReason/',
		rejectionReason: '/api/dashboard/rejectionReason/',
		srCancelReason: '/api/dashboard/srCancelReason/',
		srStatus: '/api/dashboard/srStatus/',
		quoteStatus: '/api/dashboard/quoteStatus/',
		commissionTax: '/api/dashboard/commissionTax/',
		serviceRequest:'/api/dashboard/serviceRequest/',
		disciplines: '/api/dashboard/disciplines/',
		technologies: '/api/v1/technologies/',
		stats: '/api/v1/stats',
		configurations: '/api/v1/configurations/',
		sub_admins: '/api/dashboard/sub-admins/',
		users: '/api/dashboard/users/',
		providers: '/api/dashboard/providers/',
		add_categories: '/api/dashboard/add_category',
		location: '/api/dashboard/location/',
		profile: '/api/dashboard/admin-profile',
		image_path : '/api/v1/download/'
	}
};
