import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

export default function CustBottom() {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "70vw",
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <Button
        variant="contained"
        color="green"
        className={classes.button}
        style={{
          backgroundColor: "orange",
          color: "white",
          margin: "10px",
        }}
      >
        View Quotes List
      </Button>
      <Button
        variant="contained"
        color="green"
        className={classes.button}
        // style={{
        //   backgroundColor: "orange",
        //   color: "white",
        //   margin: "10px"
        // }}
      >
        View Warranty Request
      </Button>
      <Button
        variant="contained"
        color="green"
        className={classes.button}
        // style={{
        //    backgroundColor: "orange",
        //    color: "white",
        //    margin: "10px"
        // }}
      >
        View Dispute Request
      </Button>
    </div>
  );
}
