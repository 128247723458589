import { authHeader } from './authHeader';
import { authHeaderStore } from './authHeaderStore';
import config from './../data/config';
import base64 from 'base-64';
// import queryString from 'query-string';
// import axios from 'axios';

export const APIService = {
	login,
	logout,
	fetchAllCategories,
	addCategory,
	updateCategory,
	deleteCategory,
	fetchAllSubCategories,
	addSubCategory,
	updateSubCategory,
	adminProfile,
	fetchAllUsers,
	fetchAllProviders,
	deleteSubCategory,
	addUser,
	updateUser,
	deleteUser,
	statusUser,
	addProvider,
	updateProvider,
	deleteProvider,
	fetchCountry,
	fetchState,
	fetchCity,
	fetchAllServiceType,
	fetchSubCategory,
	addServiceType,
	updateServiceType,
	deleteServiceType,
	fetchAllSubAdmins,
	addSubAdmin,
	updateSubAdmin,
	deleteSubAdmin,
	fetchAllAdminRoleType,
	statusAdmin,
	statusCategory,
	statusSubCategory,
	statusServiceType,
	fetchAllHouseType,
	statusHouseType,
	deleteHouseType,
	addHoustType,
	updateHoustType,
	fetchAllTimeSlots,
	statusTimeSlots,
	deleteTimeSlots,
	addTimeSlots,
	updateTimeSlots,

	fetchAllWithdrwalReason,
	statusWithdrwalReason,
	deleteWithdrwalReason,
	addWithdrwalReason,
	updateWithdrwalReason,

	fetchAllQuoteRejection,
	statusQuoteRejection,
	deleteQuoteRejection,
	addQuoteRejection,
	updateQuoteRejection,

	fetchAllSrCancelReason,
	statusSrCancelReason,
	deleteSrCancelReason,
	addSrCancelReason,
	updateSrCancelReason,

	fetchAllSrStatus,
	statusSrStatus,
	deleteSrStatus,
	addSrStatus,
	updateSrStatus,

	fetchAllquoteStatus,
	statusquoteStatus,
	deletequoteStatus,
	addquoteStatus,
	updatequoteStatus,

	fetchAllcommissionTax,
	statuscommissionTax,
	deletecommissionTax,
	addcommissionTax,
	updatecommissionTax,

	fetchServiceType,

	fetchAllServiceRequest,
	addServiceRequest,
	updateServiceRequest,
	statusServiceRequest,
	deleteServiceRequest,

	userDetails
};

const baseUrl =
	config.url === 'baseurl'
		? config.baseurl
		: config.url === 'localurl'
		? config.localurl
		: config.url === 'ngrokurl'
		? config.ngrokurl
		: config.url === 'sandbox'
		? config.sandbox
		: window.location.hostname;

// const instance = axios.create({ baseURL: baseUrl });

function login(user, password) {
	// console.log(user, password);
	const basicAuthString = base64.encode(user + ':' + password);
	const requestOptions = {
		method: 'POST',
		headers: authHeader(user, password)
		// body: JSON.stringify({ email: user, password }),
	};

	// return instance
	//   .get(config.endpoints.login, { headers: authHeader(user, password) })
	//   .then(({ data: user }) => {
	//     if (user) {
	//       user.auth_data = basicAuthString;
	//       localStorage.setItem('user', JSON.stringify(user));
	//     }
	//     return user;
	//   });

	// return fetch(baseUrl + config.endpoints.login, requestOptions)
	//   .then(_handleResponse)
	//   .then(async (user) => {
	//     // console.log(user)
	//     if (user) {
	//       await localStorage.setItem("user", JSON.stringify(user));
	//       await localStorage.setItem("token", user.token);
	//     }
	//     return user;
	//   });
	// console.log(baseUrl + config.endpoints.login);
	return fetch(baseUrl + config.endpoints.login, requestOptions)
		.then(_handleResponse)
		.then(async (user) => {
			if (user) {
				user.auth_data = basicAuthString;
				await localStorage.setItem('user', JSON.stringify(user));
				await localStorage.setItem('token', basicAuthString);
			}
			return user;
		});
}

function logout() {
	localStorage.removeItem('user');
	localStorage.removeItem('units');
	localStorage.removeItem('categories');
	localStorage.removeItem('orderStatuses');
}

async function fetchAllSubAdmins() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.sub_admins, requestOptions)
		.then(_handleResponse)
		.then((users) => {
			return users;
		});
}




function addSubAdmin(body) {
	// console.log(body);


	var formData = new FormData();
	formData.append('profile_image', body.profile_image);
	formData.append('driving_image', body.driving_image);
	formData.append('first_name', body.first_name);
	formData.append('last_name', body.last_name);
	formData.append('middle_name', body.middle_name);
	formData.append('email', body.email);
	formData.append('password', body.password);
	formData.append('role_type', body.role_type);
	formData.append('country', body.country);
	formData.append('state', body.state);
	formData.append('city', body.city);
	formData.append('phone', body.phone);
	formData.append('zip_code', body.zip_code);
	formData.append('address_1', body.address_1);
	formData.append('address_2', body.address_2);

	const requestOptions = {
		method: 'POST',
		headers: authHeaderStore(),
		body: formData
	};

	return fetch(baseUrl + config.endpoints.sub_admins, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function updateSubAdmin(id, body) {

	var formData = new FormData();
	formData.append('first_name', body.first_name);
	formData.append('last_name', body.last_name);
	formData.append('middle_name', body.middle_name);
	formData.append('email', body.email);
	formData.append('role_type', body.role_type);
	formData.append('country', body.country);
	formData.append('state', body.state);
	formData.append('city', body.city);
	formData.append('phone', body.phone);
	formData.append('zip_code', body.zip_code);
	formData.append('address_1', body.address_1);
	formData.append('address_2', body.address_2);
	
	if (body.profile_change) {
		formData.append('profile_image', body.profile_image);
	}
	formData.append('profile_change', body.profile_change);

	if (body.driving_change) {
		formData.append('driving_image', body.driving_image);
	}
	formData.append('driving_change', body.driving_change);


	if(body.password_change){
		formData.append('password', body.password);
	}

	const requestOptions = {
		method: 'PUT',
		headers: authHeaderStore(),
		body: formData
	};

	return fetch(baseUrl + config.endpoints.sub_admins + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function statusAdmin(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.sub_admins + id + '/status', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

function deleteSubAdmin(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.sub_admins + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

async function fetchAllAdminRoleType(token) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.sub_admins + 'roles-type', requestOptions)
		.then(_handleResponse)
		.then((users) => {
			return users;
		});
}

async function fetchAllUsers(token) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.users, requestOptions)
		.then(_handleResponse)
		.then((users) => {
			return users;
		});
}

async function fetchAllProviders(token) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.providers, requestOptions)
		.then(_handleResponse)
		.then((providers) => {
			return providers;
		});
}

function fetchAllCategories() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.categories, requestOptions)
		.then(_handleResponse)
		.then(async (categories) => {
			// await localStorage.setItem('categories', JSON.stringify(categories));
			return categories;
		});
}

function addCategory(body) {
	// console.log(body);

	var formData = new FormData();
	formData.append('category_image', body.category_image);
	formData.append('description', body.description);
	formData.append('category_name', body.name);
	formData.append('status', body.status);

	const requestOptions = {
		method: 'POST',
		headers: authHeaderStore(),
		body: formData
	};

	return fetch(baseUrl + config.endpoints.add_categories, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function updateCategory(id, body) {
	// const categoryBody = { category_name: body };

	var formData = new FormData();

	formData.append('description', body.description);
	formData.append('category_name', body.name);
	formData.append('status', body.status);

	if (body.category_image_change) {
		formData.append('category_image', body.category_image);
	}
	formData.append('category_image_change', body.category_image_change);

	const requestOptions = {
		method: 'PUT',
		headers: authHeaderStore(),
		body: formData
	};

	return fetch(baseUrl + config.endpoints.categories + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function deleteCategory(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.categories + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function fetchAllSubCategories() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.subcategories, requestOptions)
		.then(_handleResponse)
		.then(async (categories) => {
			// console.log('categories :: ' + categories);

			await localStorage.setItem('subcategories', JSON.stringify(categories));
			return categories;
		});
}

function addSubCategory(body) {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.subcategories, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function updateSubCategory(id, body) {
	// console.log(body);
	// const categoryBody = { name: body };
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.subcategories + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function deleteSubCategory(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.subcategories + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function adminProfile() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.profile, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

async function fetchServiceType(sub_category_id) {
	let body = { sub_category_id: sub_category_id };
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.subcategories + 'service-type', requestOptions)
		.then(_handleResponse)
		.then((location) => {
			return location;
		});
}

function addUser(body) {
	var formData = new FormData();
	formData.append('profile_photo', body.profile_image);
	formData.append('first_name', body.first_name);
	formData.append('middle_name', body.middle_name);
	formData.append('last_name', body.last_name);
	formData.append('email', body.email);
	formData.append('password', body.password);
	formData.append('address_1', body.address_1);
	formData.append('address_2', body.address_2);
	formData.append('city', body.city);
	formData.append('state', body.state);
	formData.append('country', body.country);
	formData.append('zip_code', body.zip_code);
	formData.append('phone', body.phone);
	formData.append('role_type', body.role_type);
	formData.append('status', body.status);
	formData.append('bank_name', body.bank_name);
	formData.append('account_name', body.account_name);
	formData.append('account_number', body.account_number);
	formData.append('routing_number', body.routing_number);
	formData.append('account_type', body.account_type);
	formData.append('account_nick', body.account_nick);

	const requestOptions = {
		method: 'POST',
		headers: authHeaderStore(),
		body: formData
	};

	return fetch(baseUrl + config.endpoints.users, requestOptions)
		.then(_handleResponse)
		.then((user) => {
			return user;
		});
}

function updateUser(id, body) {
	var formData = new FormData();

	if (body.profile_change) {
		formData.append('profile_photo', body.profile_image);
	}

	if(body.password_change){
		formData.append('password', body.password);
	}

	formData.append('first_name', body.first_name);
	formData.append('middle_name', body.middle_name);
	formData.append('last_name', body.last_name);
	formData.append('email', body.email);
	formData.append('address_1', body.address_1);
	formData.append('address_2', body.address_2);
	formData.append('city', body.city);
	formData.append('state', body.state);
	formData.append('country', body.country);
	formData.append('zip_code', body.zip_code);
	formData.append('phone', body.phone);
	formData.append('role_type', body.role_type);
	formData.append('status', body.status);
	formData.append('bank_name', body.bank_name);
	formData.append('account_name', body.account_name);
	formData.append('account_number', body.account_number);
	formData.append('routing_number', body.routing_number);
	formData.append('account_type', body.account_type);
	formData.append('account_nick', body.account_nick);

	const requestOptions = {
		method: 'PUT',
		headers: authHeaderStore(),
		body: formData
	};

	return fetch(baseUrl + config.endpoints.users + id, requestOptions)
		.then(_handleResponse)
		.then((user) => {
			return user;
		});
}

function deleteUser(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.users + id, requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

function statusUser(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.users + id + '/status', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

async function fetchCountry(token) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.location + 'country', requestOptions)
		.then(_handleResponse)
		.then((location) => {
			return location;
		});
}

async function fetchState(country_id) {
	let body = { country_id: country_id };
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.location + 'state', requestOptions)
		.then(_handleResponse)
		.then((location) => {
			return location;
		});
}

async function fetchCity(state_id) {
	let body = { state_id: state_id };
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.location + 'city', requestOptions)
		.then(_handleResponse)
		.then((location) => {
			return location;
		});
}

function addProvider(body) {
	var formData = new FormData();
	formData.append('profile_photo', body.profile_photo);
	formData.append('first_name', body.first_name);
	formData.append('last_name', body.last_name);
	formData.append('email', body.email);
	formData.append('password', body.password);
	formData.append('address_1', body.address_1);
	formData.append('city', body.city);
	formData.append('state', body.state);
	formData.append('country', body.country);
	formData.append('zip_code', body.zip_code);
	formData.append('phone', body.phone);

	const requestOptions = {
		method: 'POST',
		headers: authHeaderStore(),
		body: formData
	};

	return fetch(baseUrl + config.endpoints.providers, requestOptions)
		.then(_handleResponse)
		.then((user) => {
			return user;
		});
}

function updateProvider(id, body) {
	var formData = new FormData();

	if (body.profile_change) {
		formData.append('profile_photo', body.profile_photo);
	}

	formData.append('first_name', body.first_name);
	formData.append('last_name', body.last_name);
	formData.append('email', body.email);
	formData.append('address_1', body.address_1);
	formData.append('city', body.city);
	formData.append('state', body.state);
	formData.append('country', body.country);
	formData.append('zip_code', body.zip_code);
	formData.append('phone', body.phone);
	formData.append('profile_change', body.profile_change);

	const requestOptions = {
		method: 'PUT',
		headers: authHeaderStore(),
		body: formData
	};

	return fetch(baseUrl + config.endpoints.providers + id, requestOptions)
		.then(_handleResponse)
		.then((user) => {
			return user;
		});
}

function deleteProvider(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.providers + id, requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

function fetchAllServiceType() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.serviceType, requestOptions)
		.then(_handleResponse)
		.then(async (serviceType) => {
			await localStorage.setItem('serviceType', JSON.stringify(serviceType));
			return serviceType;
		});
}

function addServiceType(body) {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.serviceType, requestOptions)
		.then(_handleResponse)
		.then((serviceType) => {
			return serviceType;
		});
}

function updateServiceType(id, body) {
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.serviceType + id, requestOptions)
		.then(_handleResponse)
		.then((serviceType) => {
			return serviceType;
		});
}

function deleteServiceType(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.serviceType + id, requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

async function fetchSubCategory(category_id) {
	let body = { category_id: category_id };
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.categories + 'sub-category', requestOptions)
		.then(_handleResponse)
		.then((location) => {
			return location;
		});
}

function statusCategory(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.categories + id + '/status', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

function statusSubCategory(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.subcategories + id + '/status', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

function statusServiceType(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.serviceType + id + '/status', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

function _handleResponse(response) {
	return response.text().then((text) => {
		let data = text && JSON.parse(text);
		data.statusText = response.statusText;
		if (!response.ok) {
			if (response.status === 401) {
				// console.log(response);
				// logout();
				// if (new URL(response.url).pathname !== "/api/dashboard/admins/login") {
				//   window.location.reload(true);
				// }
			}
			return Promise.reject(data);
		}

		return data;
	});
}


function fetchAllHouseType() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.housetype, requestOptions)
		.then(_handleResponse)
		.then(async (categories) => {
			// await localStorage.setItem('categories', JSON.stringify(categories));
			return categories;
		});
}

function addHoustType(body) {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.housetype, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}


function updateHoustType(id, body) {
	
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.housetype + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function statusHouseType(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.housetype + id + '/status', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

function deleteHouseType(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.housetype + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}



function fetchAllTimeSlots() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.timeslots, requestOptions)
		.then(_handleResponse)
		.then(async (categories) => {
			// await localStorage.setItem('categories', JSON.stringify(categories));
			return categories;
		});
}

function addTimeSlots(body) {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.timeslots, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}


function updateTimeSlots(id, body) {
	
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.timeslots + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function statusTimeSlots(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.timeslots + id + '/status', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

function deleteTimeSlots(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.timeslots + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}


// withdrawal reason 


function fetchAllWithdrwalReason() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.withdrawalReason, requestOptions)
		.then(_handleResponse)
		.then(async (categories) => {
			// await localStorage.setItem('categories', JSON.stringify(categories));
			return categories;
		});
}

function addWithdrwalReason(body) {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.withdrawalReason, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}


function updateWithdrwalReason(id, body) {
	
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.withdrawalReason + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function statusWithdrwalReason(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.withdrawalReason + id + '/status', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

function deleteWithdrwalReason(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.withdrawalReason + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}


// rejection reason


function fetchAllQuoteRejection() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.rejectionReason, requestOptions)
		.then(_handleResponse)
		.then(async (categories) => {
			// await localStorage.setItem('categories', JSON.stringify(categories));
			return categories;
		});
}

function addQuoteRejection(body) {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.rejectionReason, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}


function updateQuoteRejection(id, body) {
	
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.rejectionReason + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function statusQuoteRejection(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.rejectionReason + id + '/status', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

function deleteQuoteRejection(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.rejectionReason + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

// sr cancel reason 


function fetchAllSrCancelReason() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.srCancelReason, requestOptions)
		.then(_handleResponse)
		.then(async (categories) => {
			// await localStorage.setItem('categories', JSON.stringify(categories));
			return categories;
		});
}

function addSrCancelReason(body) {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.srCancelReason, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}


function updateSrCancelReason(id, body) {
	
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.srCancelReason + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function statusSrCancelReason(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.srCancelReason + id + '/status', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

function deleteSrCancelReason(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.srCancelReason + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}



// Sr Status


function fetchAllSrStatus() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.srStatus, requestOptions)
		.then(_handleResponse)
		.then(async (categories) => {
			// await localStorage.setItem('categories', JSON.stringify(categories));
			return categories;
		});
}

function addSrStatus(body) {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.srStatus, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}


function updateSrStatus(id, body) {
	
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.srStatus + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function statusSrStatus(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.srStatus + id + '/status', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

function deleteSrStatus(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.srStatus + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

// Quote Status


function fetchAllquoteStatus() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.quoteStatus, requestOptions)
		.then(_handleResponse)
		.then(async (categories) => {
			// await localStorage.setItem('categories', JSON.stringify(categories));
			return categories;
		});
}

function addquoteStatus(body) {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.quoteStatus, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}


function updatequoteStatus(id, body) {
	
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.quoteStatus + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function statusquoteStatus(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.quoteStatus + id + '/status', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

function deletequoteStatus(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.quoteStatus + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}



// Charge Factor


function fetchAllcommissionTax() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.commissionTax, requestOptions)
		.then(_handleResponse)
		.then(async (categories) => {
			// await localStorage.setItem('categories', JSON.stringify(categories));
			return categories;
		});
}

function addcommissionTax(body) {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.commissionTax, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}


function updatecommissionTax(id, body) {
	
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	return fetch(baseUrl + config.endpoints.commissionTax + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function statuscommissionTax(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.commissionTax + id + '/status', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

function deletecommissionTax(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.commissionTax + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}





// service request



function fetchAllServiceRequest() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.serviceRequest, requestOptions)
		.then(_handleResponse)
		.then(async (categories) => {
			// await localStorage.setItem('categories', JSON.stringify(categories));
			return categories;
		});
}


function addServiceRequest(body) {
	// console.log(body);


	var formData = new FormData();

	body.images.forEach((file) => formData.append('images[]', file));
	
	// formData.append('first_name', body.first_name);
	// formData.append('last_name', body.last_name);
	formData.append('user_id', body.user_id);
	formData.append('title', body.title);
	formData.append('address_type', body.address_type);
	formData.append('description', body.description);
	formData.append('time_slot', body.time);
	formData.append('service_date', body.date);
	formData.append('country', body.country);
	formData.append('state', body.state);
	formData.append('city', body.city);
	formData.append('category_id', body.category_id);
	formData.append('sub_category_id', body.sub_category_id);
	formData.append('service_type_id', body.service_type);
	formData.append('zip_code', body.zip_code);
	formData.append('address_1', body.address_1);
	formData.append('address_2', body.address_2);

	// return formData;

	const requestOptions = {
		method: 'POST',
		headers: authHeaderStore(),
		body: formData
	};

	return fetch(baseUrl + config.endpoints.serviceRequest, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function updateServiceRequest(id, body) {
	var formData = new FormData();

	if (body.profile_change) {
		body.images.forEach((file) => formData.append('images[]', file));
	}

	
	// formData.append('first_name', body.first_name);
	formData.append('profile_change', body.profile_change);
	// formData.append('last_name', body.last_name);
	formData.append('user_id', body.user_id);
	formData.append('title', body.title);
	formData.append('address_type', body.address_type);
	formData.append('description', body.description);
	formData.append('time_slot', body.time);
	formData.append('service_date', body.date);
	formData.append('country', body.country);
	formData.append('state', body.state);
	formData.append('city', body.city);
	formData.append('category_id', body.category_id);
	formData.append('sub_category_id', body.sub_category_id);
	formData.append('service_type_id', body.service_type);
	formData.append('zip_code', body.zip_code);
	formData.append('address_1', body.address_1);
	formData.append('address_2', body.address_2);

	// return formData;

	const requestOptions = {
		method: 'PUT',
		headers: authHeaderStore(),
		body: formData
	};

	return fetch(baseUrl + config.endpoints.serviceRequest+id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}


function statusServiceRequest(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.serviceRequest + id + '/status', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}


function deleteServiceRequest(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.serviceRequest + id, requestOptions)
		.then(_handleResponse)
		.then((category) => {
			return category;
		});
}

function userDetails(id) {
	// console.log(id)
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(baseUrl + config.endpoints.users + id + '/details', requestOptions)
		.then(_handleResponse)
		.then((res) => {
			return res;
		});
}

