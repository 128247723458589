export default function () {
	return [
		{
			title: 'Dashboard',
			to: '/dashboard',
			htmlBefore: '<i class="material-icons">dashboard</i>',
			htmlAfter: ''
		},
		{
			title: 'Sub-Admin ',
			htmlBefore: '<i class="material-icons">people</i>',
			to: '/sub-admins'
		},
		{
			title: 'Users Management',
			htmlBefore: '<i class="material-icons">people</i>',
			to: '/users'
		},
		{
			title: 'Service Request Management',
			htmlBefore: '<i class="material-icons">people</i>',
			to: '/service-request-mgt'
		},
		{
			title: 'Quote Management',
			htmlBefore: '<i class="material-icons">people</i>',
			to: '/quote-management'
		},
		{
			title: 'Warranty And Dispute Management',
			htmlBefore: '<i class="material-icons">people</i>',
			to: '/warranty-dispute-mgt'
		},
		{
			title: 'Coupon Management',
			htmlBefore: '<i class="material-icons">people</i>',
			to: '/coupon-managemnet'
		},
		{
			title: 'Adjustment Management',
			htmlBefore: '<i class="material-icons">people</i>',
			to: '/adjustment-management'
		},
		{
			title: 'Reports Management',
			htmlBefore: '<i class="material-icons">people</i>',
			to: '/reports-managemnet'
		},
		{
			title: 'Categories',
			htmlBefore: '<i class="material-icons">category</i>',
			to: '/categories'
		},
		{
			title: 'Sub Categories',
			htmlBefore: '<i class="material-icons">category</i>',
			to: '/sub-categories'
		},
		{
			title: 'Service Type',
			htmlBefore: '<i class="material-icons">category</i>',
			to: '/service-type'
		},
		{
			title: 'Data Set Management',
			htmlBefore: '<i class="material-icons">category</i>',
			to: '/data-set'
		},
		{
			title: 'Service Request',
			htmlBefore: '<i class="material-icons">category</i>',
			to: '/service-request'
		},
		{
			title: 'Commission Tax',
			htmlBefore: '<i class="material-icons">category</i>',
			to: '/charge-factor'
		},
		{
			title: 'Forms & Components',
			htmlBefore: '<i class="material-icons">view_module</i>',
			to: '/components-overview'
		}
	];
}
