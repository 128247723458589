import React from "react";
import Calender from "../ServiceRequest/Calender";
import ContentHeader from "../../components/common/ContentHeader";
import MainTitle from "../../components/common/MainTitle";
import MaterialTable from "material-table";


import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

const WarrantyMgt = (props) => {
  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Warranty Management" />
        <Row>
          <Col lg="12" className="mb-4">
          <ContentHeader>
              <Button  onClick={()=>{props.history.push('/warranty-dispute-mgt')}} outline theme="primary" className="mb-2 mr-1">
                Back
              </Button>
            </ContentHeader>
            <ListGroupItem>
              <Row>
                <Col md={{ size: 2, order: 2 }}>
                  <label>User Type</label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 5, order: 4 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                  />
                </Col>
                <Col md={{ size: 5, order: 4 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="fistName"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Customer Mobile #</label>
                  <FormInput
                    id="feMobile"
                    type="text"
                    placeholder="Mobile"
                    name="mobile"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label>Customer Email ID</label>
                  <FormInput
                    id="feEmail"
                    type="email"
                    placeholder="Email"
                    name="email"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Customer ID </label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Customer Id"
                    name="customerId"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label>Service Category</label>
                  <FormSelect id="feServiceCategory">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label>Service Sub-Category</label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Service Request ID </label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Service Request ID"
                    name="customerId"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feAddress">Service Address Line 1</label>
                  <FormInput
                    id="feAddress"
                    type="text"
                    placeholder="Service Address Line 1"
                    name="address_1"
                  />
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feCity">City</label>
                  <FormSelect id="feCity">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feState">State</label>
                  <FormSelect id="feState">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feZip">Zip Code</label>
                  <FormInput
                    id="feZip"
                    type="text"
                    placeholder="Zip Code"
                    name="zip_code"
                  />
                </Col>
               
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feSrStatus">SR Status</label>
                  <FormSelect id="feState">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
              
               
              
                
               
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <label>Service Request Creation Date Range</label>
                  <Calender />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <label>Service Request Schedule Date Range</label>
                  <Calender />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <Button outline theme="primary" className="mt -4 mb-2 ml-4">
                    Apply Filter
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
      <Container fluid className="main-content-container px-4">
        <Row>
          <Col lg="12" md={{ size: 4, order: 4 }} className="mb-6">
            <h4>
              {" "}
              <b>Warranty Requests List </b>
            </h4>
          </Col>
          <Col lg="12" className="mb-6"></Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <MaterialTable
                  title=""
                  columns={[
                    { title: "ID", field: "id" },
                    { title: "Warranty Details", field: "srId" },
                    { title: "Warranty Creation Date", field: "srCreationDate" },
                    { title: "SR ID", field: "srScheduledDate" },
                    { title: "Quote ID", field: "srScheduledDate" },
                    { title: "Customer ID", field: "srScheduledDate" },
                    { title: "Service Provider ID", field: "user.first_name" },
                    { title: "Warranty Closure Date", field: "user.last_name" },
                    { title: "Warranty Expiry Date", field: "user.last_name" },
                    { title: "Warranty Closure Comments", field: "user.last_name" },
                  ]}
                  data={[
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                  ]}
                  options={{
                    search: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportCsv: (columns, data) => {},
                  }}
                  actions={[
                    {
                        icon: "chat",
                        tooltip: "View Chat",
                      },
                      {
                        icon: "comment",
                        tooltip: "View Comment",
                        onClick: (event, data) => {
                          console.log(data);
                          console.log(event);
                          props.history.push("/view-quote-details");
                        },
                      },
                    {
                      icon: "visibility",
                      tooltip: "Warranty Details",
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push("/view-quote-details");
                      },
                    },
                    

                    {
                      icon: "edit",
                      tooltip: "Edit Warranty",
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push("/edit-quote");
                      },
                    },
                    {
                      icon: "delete",
                      tooltip: " Delete Warranty ",
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push("/view-quote-details");
                      },
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default WarrantyMgt;
