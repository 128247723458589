import React from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Button,
	// ButtonGroup,
	FormTextarea,
	Form,
	FormInput,
	Alert,
	FormSelect,
	InputGroupAddon,
	InputGroup
} from 'shards-react';
// import PageTitle from '../../components/common/PageTitle';
import MainTitle from '../../components/common/MainTitle';
import ContentHeader from '../../components/common/ContentHeader';
// import Test from '../../components/common/Test';
import Loader from '../../components/Loader/Loader';
import { Redirect } from 'react-router-dom';
import { APIService } from '../../utils/APIService';
import userLoginStatus from '../../utils/userLoginStatus';
import  moment  from "moment";
class AddEditServiceRequest extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			loginStatus: undefined,
			success: false,
			redirect: false,
			loading: false,
			internetConnected: true,
			visible: false,
			update: this.props.location.state ? this.props.location.state.update : false,
			id: this.props.location.state ? this.props.location.state.id : '',
			address_type : this.props.location.state ? this.props.location.state.address_type : 1,
			first_name: this.props.location.state ? this.props.location.state.first_name : '',
			user_id : this.props.location.state ? this.props.location.state.user_id : '',
			last_name: this.props.location.state ? this.props.location.state.last_name : '',
			country: this.props.location.state ? this.props.location.state.country : '',
			state: this.props.location.state ? this.props.location.state.state : '',
			city: this.props.location.state ? this.props.location.state.city : '',
			address_1: this.props.location.state ? this.props.location.state.address_1 : '',
			address_2 : this.props.location.state ? this.props.location.state.address_2 : '',
			zip_code: this.props.location.state ? this.props.location.state.zip_code : '',
			category_id: this.props.location.state ? this.props.location.state.category_id : '',
			sub_category_id : this.props.location.state ? this.props.location.state.sub_category_id : '',
			service_type : this.props.location.state ? this.props.location.state.service_type : '',
			images: [],
			old_images:this.props.location.state ? this.props.location.state.old_images : [],
			date : this.props.location.state ? this.props.location.state.date : '',
			time: this.props.location.state ? this.props.location.state.time : '',
			title:this.props.location.state ? this.props.location.state.title : '',
			description : this.props.location.state ? this.props.location.state.description : '',
			profile_change : this.props.location.state ? this.props.location.state.profile_change : false,
			imgSrc:'http://18.217.45.92:3334/api/v1/download/',
			countries: [],
			states: [],
			cities: [],
			category:[],
			subCategory:[],
			serviceType:[],
			timesSlot:[],
			form_step: 1
		};
		this._handleChange = this._handleChange.bind(this);
		this._handleChangeCountry = this._handleChangeCountry.bind(this);
		this._handleChangeState = this._handleChangeState.bind(this);
		this._handleChangeCity = this._handleChangeCity.bind(this);
		this._handleChangeImage = this._handleChangeImage.bind(this);
		this._handleSubmitAdd = this._handleSubmitAdd.bind(this)
		this._handleSubmitUpdate = this._handleSubmitUpdate.bind(this)
		this._handleChangeCategory = this._handleChangeCategory.bind(this)
		this._handleChangeSubCategory = this._handleChangeSubCategory.bind(this)

		this._handleChangeServiceType = this._handleChangeServiceType.bind(this)
		
		this.dismiss = this.dismiss.bind(this);
	}

	componentDidMount() {
		if (this.state.loginStatus === undefined) {
			userLoginStatus().then(
				(value) => {
					this._fetchCountryData();
					this._fetchCategoryData();
					this._fetchTimes();
					if (this.state.update) {
						this._fetchStateData();
						this._fetchCityData();
						this._fetchSubListData();
						this._fetchServiceTypeListData()
					}
					this.setState({
						loginStatus: true,
						loading: false
					});
				},
				(reason) => {
					this.setState({ loginStatus: false });
				}
			);
		}
	}


	async _handleChangeCategory(e) {
		await this.setState({ category_id: e.target.value });
		this._fetchSubListData();
	}

	async _handleChangeSubCategory(e) {
		await this.setState({ sub_category_id: e.target.value });
		this._fetchServiceTypeListData()
	}
	
	_handleChangeServiceType(e){
		this.setState({ service_type: e.target.value });
	}

	 _handleChange(e) {
		const { name, value } = e.target;
		
		 this.setState({ [name]: value });
		if(name=='user_id'){
			this._fetchUser(value)
		}
		
	}

	async _handleChangeCountry(e) {
		await this.setState({ country: e.target.value });
		this._fetchStateData();
	}

	async _handleChangeState(e) {
		await this.setState({ state: e.target.value });
		this._fetchCityData();
	}

	_handleChangeCity(e) {
		this.setState({ city: e.target.value });
	}
	
	_fetchCategoryData = () => {
		APIService.fetchAllCategories().then(
			(units) => {
				// console.log(units);
				this.setState({
					category: units
				});
			},
			(error) => this.setState({ internetConnected: false })
		);
	};
	_fetchSubListData = () => {
		const { category_id } = this.state;
		APIService.fetchSubCategory(category_id).then(
			(units) => {
				this.setState({
					subCategory: units
				});
			},
			(error) => this.setState({ internetConnected: false })
		);
	};

	_fetchUser = (user_id) => {
		APIService.userDetails(user_id).then(
			(units) => {
				this.setState({
					first_name: units.first_name,
					last_name:units.last_name
				});
			},
			(error) => this.setState({ internetConnected: false })
		);
	};

	_fetchServiceTypeListData = () => {
		const { sub_category_id } = this.state;
		APIService.fetchServiceType(sub_category_id).then(
			(units) => {
				this.setState({
					serviceType: units
				});
			},
			(error) => this.setState({ internetConnected: false })
		);
	};


	_fetchCountryData = () => {
		APIService.fetchCountry().then(
			(units) => {
				// console.log(units);
				this.setState({
					countries: units
				});
			},
			(error) => this.setState({ internetConnected: false })
		);
	};
	_fetchStateData = () => {
		const { country } = this.state;
		APIService.fetchState(country).then(
			(units) => {
				this.setState({
					states: units
				});
			},
			(error) => this.setState({ internetConnected: false })
		);
	};

	_fetchCityData = () => {
		const { state } = this.state;
		APIService.fetchCity(state).then(
			(units) => {
				this.setState({
					cities: units
				});
			},
			(error) => this.setState({ internetConnected: false })
		);
	};

	_fetchTimes = () => {
		APIService.fetchAllTimeSlots().then(
			(units) => {
				this.setState({
					timesSlot: units
				});
			},
			(error) => this.setState({ internetConnected: false })
		);
	};

	_handleChangeImage(e) {
		const images = [...this.state.images]; // Spread syntax creates a shallow copy
		images.push(...e.target.files);
		this.setState({ images  })
		this.setState({ profile_change: true });


		// var reader = new FileReader();
		// var url = reader.readAsDataURL(e.target.files[0]);

		// reader.onloadend = function (e) {
		// 	this.setState({
		// 		imgSrc: [reader.result]
		// 	})
		// 	}.bind(this);


	}


	_handleSubmitAdd(e) {
		e.preventDefault();
		this.setState({ loading: true });
		// const { firstName, lastName } = this.state;
		APIService.addServiceRequest(this.state).then(
			(unit) => {
				this.setState({
					success: true,
					loading: false,
					redirect: true,
					redirectPath: '/service-request',
					redirectData: {
						visible: true,
						alertStyle: 'success',
						alertIcon: 'fa fa-check mx-2',
						alertMessage: 'Service Request added successfully.'
					}
				});
			},
			(error) => {
				alert(JSON.stringify(error, null, 2));
				this.setState({
					success: false,
					loading: false,
					visible: true,
					alertStyle: 'danger',
					alertIcon: 'fa fa-exclamation mx-2',
					alertMessage: error.errorMessage
				});
			}
		);
	}

	_handleSubmitUpdate(e) {
		e.preventDefault();
		// const { name } = this.state;
		const id = this.state.id;
		// console.log(id);
		APIService.updateServiceRequest(id, this.state).then(
			(unit) => {
				this.setState({
					success: true,
					loading: false,
					redirect: true,
					redirectPath: '/service-request',
					redirectData: {
						visible: true,
						alertStyle: 'success',
						alertIcon: 'fa fa-check mx-2',
						alertMessage: 'Service Request updated successfully.'
					}
				});
			},

			(error) => {
				// alert(JSON.stringify(error, null, 2));
				this.setState({
					success: false,
					loading: false,
					visible: true,
					alertStyle: 'danger',
					alertIcon: 'fa fa-exclamation mx-2',
					alertMessage: error.errorMessage
				});
			}
		);
	}

	dismiss() {
		this.setState({ visible: false });
	}

	renderCountry = (Obj, i) => {
		return (
			<option value={Obj.id} key={i} selected={this.state.country == Obj.id}>
				{Obj.name}
			</option>
		);
	};

	renderState = (Obj, i) => {
		return (
			<option value={Obj.id} key={i} selected={this.state.state == Obj.id}>
				{Obj.name}
			</option>
		);
	};
	renderCity = (Obj, i) => {
		return (
			<option value={Obj.id} key={i} selected={this.state.city == Obj.id}>
				{Obj.name}
			</option>
		);
	};

	renderCategory = (Obj, i) => {
		return (
			<option value={Obj.id} key={i} selected={this.state.category_id == Obj.id}>
				{Obj.category_name}
			</option>
		);
	};

	renderSubcategory = (Obj, i) => {
		return (
			<option value={Obj.id} key={i} selected={this.state.sub_category_id == Obj.id ? true : false} key={i}>
				{Obj.sub_category_name}
			</option>
		);
	};

	renderServiceType = (Obj, i) => {
		return (
			<option value={Obj.id} key={i} selected={this.state.service_type == Obj.id ? true : false} key={i}>
				{Obj.name}
			</option>
		);
	};

	renderTime = (Obj, i) => {
		
		return (
			<option value={Obj.id} key={i} selected={this.state.time == Obj.id ? true : false} key={i}>
				{moment('Mon 03-Jul-2017, '+Obj.start_time, 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A') + '-' + moment('Mon 03-Jul-2017, '+Obj.end_time, 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A')}
			</option>
		);
	};
	
	

	render() {
		const { loginStatus, loading, internetConnected,first_name,last_name,address_1,zip_code } = this.state;

	
		

		this.renderCountry = this.renderCountry.bind(this);

		this.renderState = this.renderState.bind(this);


		this.renderCity = this.renderCity.bind(this);

		this.renderCategory = this.renderCategory.bind(this)

		this.renderSubcategory = this.renderSubcategory.bind(this);

		const selectSubCategory =
			this.state.subCategory.length > 0 && this.state.subCategory.map(this.renderSubcategory);


		this.renderServiceType = this.renderServiceType.bind(this);
		
		const selectServiceType =
			this.state.serviceType.length > 0 && this.state.serviceType.map(this.renderServiceType);

		

		const selectCountry = this.state.countries.length > 0 && this.state.countries.map(this.renderCountry);
		const selectState = this.state.states.length > 0 && this.state.states.map(this.renderState);
		const selectCity = this.state.cities.length > 0 && this.state.cities.map(this.renderCity);
		const selectCategory = this.state.category.length > 0 && this.state.category.map(this.renderCategory);


		const selectTime = this.state.timesSlot.length > 0 && this.state.timesSlot.map(this.renderTime);

		if (this.state.redirect) {
			return (
				<Redirect
					to={{
						pathname: this.state.redirectPath,
						state: this.state.redirectData
					}}
				/>
			);
		}
		if (!internetConnected) {
			return <div>No Internet Connection.</div>;
		}
		if (loading) {
			return <Loader />;
		}
		if (loginStatus === undefined) {
			return <Loader />;
		} else if (loginStatus) {
			return (
				<div>
					<Container fluid className="px-0">
						<Alert
							theme={this.state.alertStyle || 'primary'}
							dismissible={this.dismiss}
							open={this.state.visible}
							className="mb-0"
						>
							<i className={this.state.alertIcon} /> {this.state.alertMessage}
						</Alert>
					</Container>
					<Container fluid className="main-content-container px-4">
						<MainTitle title="Service Request" />
						<Row>
							<Col>
								<Card small className="mb-4">
									<ContentHeader title={this.state.update ? 'Edit Service Request' : 'Add Service Request'}>
										<Button
											outline
											theme="primary"
											className="mb-2 mr-1"
											onClick={() => {
												if (
													window.confirm(
														'All your changes will be lost. Do you still want continue?'
													)
												) {
													this.setState({
														redirect: true,
														redirectPath: '/service-request'
													});
												}
											}}
										>
											Back
										</Button>
									</ContentHeader>
									<CardBody className="p-0 pb-3">
										<Form onSubmit={this.state.update ? this._handleSubmitUpdate : this._handleSubmitAdd}>
											<Row form>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="user_id">User ID</label>
													<FormInput
														id="user_id"
														type="text"
														placeholder="User Id"
														name="user_id"
														onChange={this._handleChange}
														value={this.state.user_id}
														required
													/>
												</Col>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feFirstName">First Name</label>
													<FormInput
														id="feFirstName"
														type="text"
														placeholder="First Name"
														name="first_name"
														onChange={this._handleChange}
														value={this.state.first_name}
														readOnly
													/>
												</Col>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feLastName">Last Name</label>
													<FormInput
														id="feLastName"
														placeholder="Last Name"
														type="text"
														name="last_name"
														onChange={this._handleChange}
														value={this.state.last_name}
														readOnly
													/>
												</Col>
											</Row>

											<Row form>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="address_type">address_type</label>
													<FormSelect id="address_type" name="address_type" onChange={this._handleChange}>
														<option value="1" selected={this.state.address_type == 1}>Home Address</option>
														<option value="0"selected={this.state.address_type == 0}>Other Address</option>
													</FormSelect>
												</Col>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feAddress">Address 1</label>
													<FormInput
														id="feAddress"
														type="text"
														placeholder="Address Line 1"
														name="address_1"
														onChange={this._handleChange}
														value={this.state.address_1}
														required
													/>
												</Col>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feAddress2">Address 2</label>
													<FormInput
														id="feAddress2"
														type="text"
														placeholder="Address Line 2"
														name="address_2"
														onChange={this._handleChange}
														value={this.state.address_2}
													/>
												</Col>
											</Row>

											<Row form>
												
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feCountry">Country</label>
													<FormSelect id="feCountry" name="country_id" onChange={this._handleChangeCountry} required>
														<option>Choose</option>
														{selectCountry}
													</FormSelect>
												</Col>
												<Col md={{ size: 4, order:4 }} className="form-group p-3">
													<label htmlFor="feState">State</label>
													<FormSelect id="feState" onChange={this._handleChangeState} required>
														<option>Choose</option>
														{selectState}
													</FormSelect>
												</Col>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feCity">City</label>
													<FormSelect id="feCity" onChange={this._handleChangeCity} required>
														<option>Choose</option>
														{selectCity}
													</FormSelect>
												</Col>
											</Row>

											
											
											
											<Row form>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feZip">Zip Code</label>
													<FormInput
														id="feZip"
														type="text"
														placeholder="Zip Code"
														name="zip_code"
														onChange={this._handleChange}
														value={this.state.zip_code}
														required
													/>
												</Col>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feDate">Service Date</label>
													<FormInput
														id="feDate"
														type="date"
														name="date"
														onChange={this._handleChange}
														value={moment(this.state.date).format('YYYY-MM-DD')}
														required
													/>
												</Col>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feTime">Service Time</label>
													<FormSelect id="feTime" onChange={this._handleChange} name="time" required>
														<option>Choose</option>
														{selectTime}
													</FormSelect>
												</Col>
											</Row>


											<Row form>

												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feCategory">Category</label>
													<FormSelect id="feCategory" onChange={this._handleChangeCategory} required>
														<option>Choose</option>
														{selectCategory}
													</FormSelect>
												</Col>
												<Col sm={{ size: 4, order: 4 }} className="form-group p-3">
													<label> Select Sub-Category </label>
													<FormSelect onChange={this._handleChangeSubCategory} required>
														<option>Choose</option>
														{selectSubCategory}
													</FormSelect>
												</Col>


												<Col sm={{ size: 4, order: 4 }} className="form-group p-3">
													<label> Select Service Type </label>
													<FormSelect onChange={this._handleChangeServiceType} required>
														<option>Choose</option>
														{selectServiceType}
													</FormSelect>
												</Col>

											</Row>

											<Row form>

												<Col md={{ size: 12, order: 12 }} className="form-group p-3">
													<label htmlFor="feTitle">Title</label>
													<FormInput
														id="feTitle"
														type="text"
														placeholder="Title"
														name="title"
														onChange={this._handleChange}
														value={this.state.title}
														required
													/>
												</Col>

												<Col md={{ size: 12, order: 12 }} className="form-group p-3">
													<label htmlFor="feDescription">Description</label>
													<FormTextarea
														id="feDescription"
														type="text"
														placeholder="Description"
														name="description"
														onChange={this._handleChange}
														value={this.state.description}
														required
													/>
												</Col>
											</Row>
											
											<Row form>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
												<label>
													Choose Image and videos..
												</label>
													<div className="custom-file mb-3">
														<FormInput
															type="file"
															className="custom-file-input"
															id="profile_image"
															name="profile_image[]"
															onChange={this._handleChangeImage}
															
															multiple 
														/>
														<label className="custom-file-label" htmlFor="profile_image">
															Choose Image...
														</label>
													</div>
												</Col>
											</Row>
												
											<Row form>
											<Col md={{ size: 4, order: 4 }} className="form-group p-3">
											<img src="/file.png" width="10%" id="profile_img"/>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<Button theme="success" type="submit">Submit</Button>
												</Col>
												</Col>
											</Row>
												
										</Form>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
				);
		} else {
			return <Redirect to="/login" />;
		}
	}
}

export default AddEditServiceRequest;
