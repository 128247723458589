import React from "react";
import Calender from "../ServiceRequest/Calender";
import ContentHeader from "../../components/common/ContentHeader";
import MainTitle from "../../components/common/MainTitle";
import MaterialTable from "material-table";
import {
  ListGroupItem,
  Row,
  Col,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

const ReportsManagement = (props) => {
  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Report Management" />
        <Row>
          <Col lg="12" className="mb-4">
            <ContentHeader>
              <Button
                onClick={() => {
                  props.history.push("/reports-managemnet");
                }}
                outline
                theme="primary"
                className="mb-2 mr-2"
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  props.history.push("/reports-managemnet");
                }}
                outline
                theme="primary"
                className="mb-2 ml-2 mr-2"
              >
                Cancel
              </Button>
            </ContentHeader>
           
         
        <Row>
          <Col>
            
                <MaterialTable
                  title=""
                  columns={[
                    { title: "ID", field: "id" },
                    { title: "Report Name", field: "srId" },
                    { title: "Report Description", field: "srId" },
                    { title: "Report Frequency", field: "srScheduledDate" },
                    { title: "Report Date, Time", field: "srScheduledDate" },
                    { title: "Automatic Scheduled(Yes/No)", field: "srScheduleDate" },
                    { title: "Email To", field: "user.first_name" },
                    
                  ]}
                  data={[
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                  ]}
                  options={{
                    search: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportCsv: (columns, data) => {},
                  }}
                  actions={[
                    {
                      icon: "check",
                      tooltip: "Check Report",
                    },

                    {
                      icon: "edit",
                      tooltip: "Edit Report ",
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push("/edit-quote");
                      },
                    },

                    {
                      icon: "delete",
                      tooltip: "Delete Report",
                    },
                    {
                      icon: "send",
                      tooltip: "Manual Send",
                    }
                  ]}
                />
                 
           
          </Col>
        </Row>
        </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ReportsManagement;
