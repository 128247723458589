import React from "react";
import Calender from "../ServiceRequest/Calender";
import ContentHeader from "../../components/common/ContentHeader";
import MainTitle from "../../components/common/MainTitle";
import ServiceRequest from "../ServiceType/list";
import MaterialTable from "material-table";
import CreateServiceRequest from "../ServiceRequest/CreateServiceRequest";
import ViewServiceReques from "../ServiceRequest/ViewServiceRequest"
import EditServiceRequest from "../ServiceRequest/EditServiceRequest"
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

const EditQuote = (props) => {
    function RadioButton() {
        const [value, setValue] = React.useState("home");
    
        const handleChange = (event) => {
          setValue(event.target.value);
        };
    
        return (
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="address"
              name="adress"
              value={value}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="YES"
              />
              <FormControlLabel
                value="office"
                control={<Radio color="primary" />}
                label="NO"
              />
            </RadioGroup>
          </FormControl>
        );
      }
  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Edit Quote" />
        <Row>
          <Col lg="12" className="mb-4">
          <ContentHeader>
              <Button
                onClick={() => {
                  props.history.push("/quote-management");
                }}
                outline
                theme="primary"
                className="mb-2 mr-1"
              >
                Back
              </Button>
            </ContentHeader>

            <ListGroupItem>
              <Row>
              <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Customer ID </label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Customer Id"
                    name="customerId"
                  />
                  
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">SR ID </label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="SR Id"
                    name="srId"
                  />
                  </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="fistName"
                  />
                </Col>
                <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">SR Status</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="SR Status"
                    name="srStatus"
                  />
                  </Col>
                  <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Service Provider ID</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Service Provider ID"
                    name="serviceProviderId"
                  />
                  </Col>
                  <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Quote ID</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Quote ID"
                    name="quoteID"
                  />
                  </Col>
                  <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feLastName">Service Provider Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Service Provider Last Name"
                    type="text"
                    name="SPlastName"
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feFirstName">Service Provider First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="Service Provider First Name"
                    name="SPfistName"
                  />
                </Col>
                  <Col md={{ size: 2, order: 4 }}>
                  <label htmlFor="feMobile">Quote Status</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Quote Status"
                    name="quoteStatus"
                  />
                  </Col>
                  <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Service Included</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Service Included"
                    name="ServiceIncluded"
                  />
                  </Col>
                  <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feMobile">Labour Quote Amount</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Labour Quote Amount"
                    name="LabourQuoteAmount"
                  />
                  </Col>
                  <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feMobile">Material Quote Amount</label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Material Quote Amount"
                    name="MaterialQuoteAmount"
                  />
                  </Col>
                  <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile" className="mb-2 mt-2 mr-2">Material Included</label>
                  <RadioButton />
                 </Col>
               
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Additional Comments </label>
                  <FormInput
                    id="feMobile"
                    type="text"
                    placeholder="Additional Comments"
                    name="AdditionalComments"
                  />
                </Col>

               
              </Row>
              <Row>
                <Col md={{ size: 8, order: 4 }}>
                  <h6>Upload Photos</h6>
                </Col>
               
               
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <Button  onClick={()=>{props.history.push('/view-chats')}} outline theme="primary" className="mb-2 mr-2">
                    Disable Quote
                  </Button>
                  <Button  onClick={()=>{props.history.push('/view-chats')}} outline theme="primary" className="mb-2 ml-2 mr-2">
                    View Chats
                  </Button>
                  <Button outline theme="primary" className="mb-2 ml-2">
                    Update
                  </Button>
                </Col>
                
               
                </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
      <Container fluid className="main-content-container px-4">
        <Row>
          <Col lg="12" md={{ size: 4, order: 4 }} className="mb-6">
            <h4>
            
              <b>Comments List </b>
            </h4>
          </Col>
          <Col lg="12" className="mb-6"></Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <MaterialTable
                  title=""
                  columns={[
                    { title: "ID", field: "id" },
                    { title: "Comments Description", field: "srId" },
                    { title: "Comment Date and Time", field: "srCreationDate" },
                    { title: "Comment From (Customer or Service Provider ID)", field: "srScheduledDate" }
                   
                  ]}
                  data={[
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                  ]}
                  options={{
                    search: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportCsv: (columns, data) => {
                    
                    },
                  }}
                  actions={[
                    {
                        icon: "check",
                        tooltip: "Check Request",
                    },
                    
                    {
                      icon: "edit",
                      tooltip: "Edit Request",
                    },

                    {
                      icon: "delete",
                      tooltip: "Delete Request",
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>

         

        </Row>
      </Container>
    </div>
  );
};
export default EditQuote;
