import React from "react";
import Calender from "./Calender";
import ContentHeader from "../../components/common/ContentHeader";
import MainTitle from "../../components/common/MainTitle";
import ServiceRequest from "../ServiceType/list";
import MaterialTable from "material-table";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";

import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormRadio,
  FormTextarea,
} from "shards-react";

const CreateServiceRequest = (props) => {
  function RadioButton() {
    const [value, setValue] = React.useState("home");

    const handleChange = (event) => {
      setValue(event.target.value);
    };

    return (
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="address"
          name="adress"
          value={value}
          onChange={handleChange}
          row
        >
          <FormControlLabel
            value="home"
            control={<Radio color="primary" />}
            label="Home"
          />
          <FormControlLabel
            value="office"
            control={<Radio color="primary" />}
            label="Other"
          />
        </RadioGroup>
      </FormControl>
    );
  }

  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Create Service Request" />
        <Row>
          <Col lg="12" className="mb-4">
            <ContentHeader>
              <Button  onClick={()=>{props.history.push('/service-request-mgt')}} outline theme="primary" className="mb-2 mr-1">
                Back
              </Button>
            </ContentHeader>

            <ListGroupItem>
              <Row>
                <Col md={{ size: 2, order: 2 }}>
                  <label>User ID</label>
                  <FormSelect id="feCustomerID">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 5, order: 4 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                  />
                </Col>
                <Col md={{ size: 5, order: 4 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="fistName"
                  />
                </Col>
                <Col md={{ size: 12, order: 4 }} className="p-3">
                  <h4>Service Address</h4>
                </Col>
                <Col md={{ size: 12, order: 4 }}>
                  <RadioButton />
                </Col>

                {/* */}
                <Col md={{ size: 6, order: 4 }}>
                  <label htmlFor="feAddress">Service Address Line 1</label>
                  <FormInput
                    id="feAddress"
                    type="text"
                    placeholder="Service Address Line 1"
                    name="address_1"
                  />
                </Col>
                <Col md={{ size: 6, order: 4 }}>
                  <label htmlFor="feAddress">Service Address Line 2</label>
                  <FormInput
                    id="feAddress"
                    type="text"
                    placeholder="Service Address Line 2"
                    name="address_2"
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feCity">City</label>
                  <FormSelect id="feCity">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feState">State</label>
                  <FormSelect id="feState">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feZip">Zip Code</label>
                  <FormInput
                    id="feZip"
                    type="text"
                    placeholder="Zip Code"
                    name="zip_code"
                  />
                </Col>
                <Col md={{ size: 3, order: 4 }}>
                  <label htmlFor="feCountry">Country</label>
                  <FormSelect id="feCountry">
                    <option>Choose</option>
                  </FormSelect>
                </Col>

                <Col md={{ size: 12, order: 4 }}>
                  <h4>Expected Service Date and Time</h4>
                </Col>
                <Col md={{ size: 6, order: 4 }}>
                  <label htmlFor="feDate">Service Date</label>
                  <FormInput
                    id="feDate"
                    type="date"
                    name="date"
                    value={"YYYY-MM-DD"}
                    required
                  />
                </Col>
                <Col md={{ size: 6, order: 4 }}>
                  <label htmlFor="feTime">Service Time</label>
                  <FormSelect id="feTime" name="time" required>
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 12, order: 4 }}>
                  <h4>Service</h4>
                </Col>

                <Col md={{ size: 4, order: 4 }}>
                  <label>Service Category</label>
                  <FormSelect id="feServiceCategory">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label>Service Sub-Category</label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Service Type </label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 12, order: 4 }}>
                  <h4>Request Title </h4>

                  <Col md={{ size: 12, order: 4 }}>
                    <FormTextarea
                      id="feTitle"
                      type="text"
                      placeholder="Provide high level summary of your request"
                      name="title"
                      value={""}
                      required
                    />
                  </Col>
                </Col>
                <Col md={{ size: 12, order: 4 }}>
                  <h4>Request Description</h4>
                  <Col md={{ size: 12, order: 12 }}>
                    <FormTextarea
                      id="feDescription"
                      type="text"
                      placeholder="Provide a brief description of the request and all the relevent details to get the most accurate quote"
                      name="description"
                      value={""}
                      required
                    />
                  </Col>
                  <Row form>
                    <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                      <label>Choose Image and videos..</label>
                      <div className="custom-file mb-3">
                        <FormInput
                          type="file"
                          className="custom-file-input"
                          id="profile_image"
                          name="profile_image[]"
                        />
                        <br />

                        <Col md={{ size: 4, order: 4 }}>
                          <Button theme="success" type="buttom" classNmae="p-1">
                            Add
                          </Button>
                        </Col>
                        <label
                          className="custom-file-label"
                          htmlFor="profile_image"
                        >
                          Choose Image...
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row form>
                    <img src="" />
                  </Row>
                  <Col sm={{ size: 6, order: 6, offset: 10 }} className="mb-5">
                <Button outline theme="primary">
                  Submit
                </Button>
              </Col>
                </Col>
              </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default CreateServiceRequest;
