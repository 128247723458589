import React,{useState} from "react";
import Calender from "./Calender";
import ContentHeader from "../../components/common/ContentHeader";
import MainTitle from "../../components/common/MainTitle";
import ServiceRequest from "../ServiceType/list";
import MaterialTable from "material-table";


import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

const ServiceRequestMainSrc = (props) => {

  const [showCreateSR,setShowCreateSR] =  useState(true)
  const [showEditSR,setEditSR] =  useState(false)
  const [viewSR,setViewSR] =  useState(false)

  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Service Request Management" />
        <Row>
          <Col lg="12" className="mb-4">
            <ContentHeader>
              <Button onClick={()=>{props.history.push('/Create-service-request')}} outline theme="primary" className="mb-2 mr-1">
                Create Service Request
              </Button>
            </ContentHeader>

            <ListGroupItem>
              <Row>
                <Col md={{ size: 2, order: 2 }}>
                  <label>User Type</label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 5, order: 4 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                  />
                </Col>
                <Col md={{ size: 5, order: 4 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="fistName"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Customer Mobile</label>
                  <FormInput
                    id="feMobile"
                    type="text"
                    placeholder="Mobile"
                    name="mobile"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label>Customer Email ID</label>
                  <FormInput
                    id="feEmail"
                    type="email"
                    placeholder="Email"
                    name="email"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Customer ID </label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Customer Id"
                    name="customerId"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label>Service Category</label>
                  <FormSelect id="feServiceCategory">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label>Service Sub-Category</label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Service Request ID </label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Service Request ID"
                    name="customerId"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feAddress">Service Address Line 1</label>
                  <FormInput
                    id="feAddress"
                    type="text"
                    placeholder="Service Address Line 1"
                    name="address_1"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feCity">City</label>
                  <FormSelect id="feCity">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feState">State</label>
                  <FormSelect id="feState">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feZip">Zip Code</label>
                  <FormInput
                    id="feZip"
                    type="text"
                    placeholder="Zip Code"
                    name="zip_code"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feSrStatus">SR Status</label>
                  <FormSelect id="feSrStatus">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col
                  md={{ size: 4, order: 4 }}
                  className="form-group p-3"
                ></Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <label>Service Request Creation Date Range</label>
                  <Calender />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <label>Service Request Schedule Date Range</label>
                  <Calender />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <Button outline theme="primary" className="mb-2 mr-1">
                    Apply Filter
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
      <Container fluid className="main-content-container px-4">
        <Row>
          <Col lg="12" md={{ size: 4, order: 4 }} className="mb-6">
            <h4>
              {" "}
              <b>Service Request List </b>
            </h4>
          </Col>
          <Col lg="12" className="mb-6"></Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <MaterialTable
                  title=""
                  columns={[
                    { title: "ID", field: "id" },
                    { title: "SR ID", field: "srId" },
                    { title: "SR Creation Date", field: "srCreationDate" },
                    { title: "SR Scheduled Date", field: "srScheduledDate" },
                    { title: "First Name", field: "user.first_name" },
                    { title: "Last Name", field: "user.last_name" },
                    { title: "Email ID", field: "user.email" },
                    { title: "Mobile Phone", field: "user.phone" },
                    {title: "Customer User ID",field: "user.user_roles.name"},
                    { title: "Service Provider ID", field: "service_date" },
                    { title: "State", field: "cityGet.states.name" },
                    { title: "City", field: "cityGet.name" },
                    { title: "SR Status", field: "timeGet.start_time" },
                    { title: "User Type", field: "cityGet.countries.name" },
                  ]}
                  data={[
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                  ]}
                  options={{
                    search: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportCsv: (columns, data) => {
                    
                    },
                  }}
                  actions={[
                    {
                      icon: "assignment",
                      tooltip: "Invoice",
                      onClick: (event, data)=>{
                        console.log(data);
                        console.log(event);
                     props.history.push(('/view-service-request'))
                      }
                    
                    },
                    {
                      icon: "assessment",
                      tooltip: "SP Payout",
                      onClick: (event, data)=>{
                        console.log(data);
                        console.log(event);
                     props.history.push(('/view-service-request'))
                      }
                      
                    },
                    {
                      icon: "visibility",
                      tooltip: "Request Details",
                      onClick: (event, data)=>{
                        console.log(data);
                        console.log(event);
                     props.history.push(('/view-service-request'))
                      }
                      
                    },
                    
                    {
                      icon: "edit",
                      tooltip: "Edit Request",
                      onClick: (event, data)=>{
                        console.log(data);
                        console.log(event);
                     props.history.push('/edit-service-request')
                      }
                    },

                    {
                      icon: "delete",
                      tooltip: "Delete Request",
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>


        </Row>
      </Container>
    </div>
  );
};
export default ServiceRequestMainSrc;
