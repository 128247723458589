import React from "react";
import Calender from "../ServiceRequest/Calender";
import ContentHeader from "../../components/common/ContentHeader";
import MainTitle from "../../components/common/MainTitle";
import ServiceRequest from "../ServiceType/list";
import MaterialTable from "material-table";
import CreateServiceRequest from "../ServiceRequest/CreateServiceRequest";
import ViewServiceReques from "../ServiceRequest/ViewServiceRequest";
import EditServiceRequest from "../ServiceRequest/EditServiceRequest";

import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";
import WarrantyMgt from "./WarrantyMgt";

const WarrantyAndDisputeMgt = (props) => {
  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Warranty And Dispute Management" />
        <Row>
          <Col lg="12" className="mb-4">
            <CardHeader className="border-bottom">
              <Row>
                <Col className="text-md-left">
                  <Button
                    onClick={() => {
                      props.history.push("/warranty-management");
                    }}
                    outline
                    theme="primary"
                    className="mb-2 mr-5 ml-2"
                  >
                    WARRANTY REQUEST MANAGEMENT
                  </Button>
                  <Button
                    onClick={() => {
                      props.history.push("/dispute-managemnet");
                    }}
                    outline
                    theme="primary"
                    className="mb-2  ml-5"
                  >
                    DISPUTE REQUEST MANAGEMENT
                  </Button>
                </Col>
              </Row>
            </CardHeader>
      
{/* 
            <ListGroupItem>
              <Row className="mb-2 mt-3">
                <Col md={{ size: 2, order: 2 }}>
                  <label>User Type</label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 5, order: 4 }}>
                  <label htmlFor="feLastName">Customer Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                  />
                </Col>
                <Col md={{ size: 5, order: 4 }}>
                  <label htmlFor="feFirstName">Customer First Name</label>
                  <FormInput
                    id="feFirstName"
                    type="text"
                    placeholder="First Name"
                    name="fistName"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Customer Mobile #</label>
                  <FormInput
                    id="feMobile"
                    type="text"
                    placeholder="Mobile"
                    name="mobile"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label>Customer Email ID</label>
                  <FormInput
                    id="feEmail"
                    type="email"
                    placeholder="Email"
                    name="email"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">User ID </label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Customer Id"
                    name="customerId"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label>Service Category</label>
                  <FormSelect id="feServiceCategory">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label>Service Sub-Category</label>
                  <FormSelect id="feUser">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feMobile">Service Request ID </label>
                  <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Service Request ID"
                    name="customerId"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feAddress">Service Address Line 1</label>
                  <FormInput
                    id="feAddress"
                    type="text"
                    placeholder="Service Address Line 1"
                    name="address_1"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feCity">City</label>
                  <FormSelect id="feCity">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feState">State</label>
                  <FormSelect id="feState">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feZip">Zip Code</label>
                  <FormInput
                    id="feZip"
                    type="text"
                    placeholder="Zip Code"
                    name="zip_code"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feSrStatus">Quote ID</label>
                  <FormInput
                    id="feZip"
                    type="text"
                    placeholder="Quote ID"
                    name="zip_code"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feSrStatus">Quote Status</label>
                  <FormSelect id="feState">
                    <option>Choose</option>
                  </FormSelect>
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feSrStatus">SP Email ID</label>
                  <FormInput
                    id="feZip"
                    type="text"
                    placeholder="SP Email ID"
                    name="SPEmailID"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feSrStatus">SP Last Name </label>
                  <FormInput
                    id="feZip"
                    type="text"
                    placeholder="SP Last Name"
                    name="SPLastName"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feSrStatus">SP First Name </label>
                  <FormInput
                    id="feZip"
                    type="text"
                    placeholder="SP First Name"
                    name="SPFirstName"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}>
                  <label htmlFor="feSrStatus">SP Mobile #</label>
                  <FormInput
                    id="feZip"
                    type="text"
                    placeholder="SP Mobile #"
                    name="SPMobile"
                  />
                </Col>
                <Col md={{ size: 4, order: 4 }}></Col>

                <Col
                  md={{ size: 4, order: 4 }}
                  className="form-group p-3"
                ></Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <label>Quote Creation Date Range</label>
                  <Calender />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <label>Quote Schedule Date Range</label>
                  <Calender />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <Button outline theme="primary" className="mb-2 mr-1">
                    Apply Filter
                  </Button>
                </Col>
              </Row>
            </ListGroupItem> */}
          </Col>
        </Row>
      </Container>
      {/* <Container fluid className="main-content-container px-4">
        <Row>
          <Col lg="12" md={{ size: 4, order: 4 }} className="mb-6">
            <h4>
              {" "}
              <b>Quote List </b>
            </h4>
          </Col>
          <Col lg="12" className="mb-6"></Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <MaterialTable
                  title=""
                  columns={[
                    { title: "ID", field: "id" },
                    { title: "Quote ID", field: "srId" },
                    { title: "Quote Creation Date", field: "srCreationDate" },
                    { title: "Quote Status", field: "srScheduledDate" },
                    { title: "Service Provider ID", field: "user.first_name" },
                    { title: "Warranty Expiry Date", field: "user.last_name" },
                    {
                      title: "Total Quote Amount to Customer",
                      field: "user.email",
                    },
                    {
                      title: "Total Quote Amount from SP",
                      field: "user.phone",
                    },
                    {
                      title: "Labour Quote Amount from SP",
                      field: "user.phone",
                    },
                    {
                      title: "Material Included (yes/No)",
                      field: "user.user_roles.name",
                    },
                    {
                      title: "Material Quote Amount from SP",
                      field: "service_date",
                    },
                  ]}
                  data={[
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                  ]}
                  options={{
                    search: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportCsv: (columns, data) => {},
                  }}
                  actions={[
                    {
                      icon: "visibility",
                      tooltip: "Quote Details",
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push("/view-quote-details");
                      },
                    },

                    {
                      icon: "edit",
                      tooltip: "Edit Request",
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push("/edit-quote");
                      },
                    },

                    {
                      icon: "delete",
                      tooltip: "Delete Request",
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container> */}
    </div>
  );
};
export default WarrantyAndDisputeMgt;
