import React from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Button,
	// ButtonGroup,
	Form,
	FormInput,
	Alert,
	FormSelect,
	InputGroupAddon,
	InputGroup
} from 'shards-react';
// import PageTitle from '../../components/common/PageTitle';
import MainTitle from '../../components/common/MainTitle';
import ContentHeader from '../../components/common/ContentHeader';
// import Test from '../../components/common/Test';
import Loader from '../../components/Loader/Loader';
import { Redirect } from 'react-router-dom';
import { APIService } from '../../utils/APIService';
import userLoginStatus from '../../utils/userLoginStatus';

class AddEditUser extends React.Component {
	constructor(props) {
		super(props);
		// console.log('props in constructor ', props);
		this.state = {
			loginStatus: undefined,
			success: false,
			redirect: false,
			loading: false,
			internetConnected: true,
			visible: false,
			update: this.props.location.state ? this.props.location.state.update : false,
			id: this.props.location.state ? this.props.location.state.id : '',
			role_type : this.props.location.state ? this.props.location.state.role_type : 1,
			password : this.props.location.state ? this.props.location.state.password : '',
			status : this.props.location.state ? this.props.location.state.status : 1,
			first_name: this.props.location.state ? this.props.location.state.first_name : '',
			last_name: this.props.location.state ? this.props.location.state.last_name : '',
			middle_name : this.props.location.state ? this.props.location.state.middle_name : '',
			email: this.props.location.state ? this.props.location.state.email : '',
			phone: this.props.location.state ? this.props.location.state.phone : '',
			country: this.props.location.state ? this.props.location.state.country : '',
			state: this.props.location.state ? this.props.location.state.state : '',
			city: this.props.location.state ? this.props.location.state.city : '',
			address_1: this.props.location.state ? this.props.location.state.address_1 : '',
			address_2 : this.props.location.state ? this.props.location.state.address_2 : '',
			zip_code: this.props.location.state ? this.props.location.state.zip_code : '',
			profile_image: this.props.location.state ? this.props.location.state.imgSrc : '',
			profile_change : this.props.location.state ? this.props.location.state.profile_change : false,
			imgSrc:this.props.location.state ? 'http://18.217.45.92:3334/api/v1/download/'+this.props.location.state.imgSrc : '/file.png',
			bank_name: this.props.location.state ? this.props.location.state.bank_name : '',
			account_name :this.props.location.state ? this.props.location.state.account_name : '',
			account_number :this.props.location.state ? this.props.location.state.account_number : '',
			routing_number :this.props.location.state ? this.props.location.state.routing_number : '',
			account_type :this.props.location.state ? this.props.location.state.account_type : 1,
			account_nick :this.props.location.state ? this.props.location.state.account_nick : '',
			password_change:false,
			countries: [],
			states: [],
			cities: [],
			form_step: 1
		};
		this._handleChange = this._handleChange.bind(this);
		this._handleChangeCountry = this._handleChangeCountry.bind(this);
		this._handleChangeState = this._handleChangeState.bind(this);
		this._handleChangeCity = this._handleChangeCity.bind(this);
		this._handleChangeImage = this._handleChangeImage.bind(this);
		this._handleSubmitAdd = this._handleSubmitAdd.bind(this)
		this._handleSubmitUpdate = this._handleSubmitUpdate.bind(this)
		
		this.dismiss = this.dismiss.bind(this);
	}

	componentDidMount() {
		if (this.state.loginStatus === undefined) {
			userLoginStatus().then(
				(value) => {
					this._fetchCountryData();
					if (this.state.update) {
						this._fetchStateData();
						this._fetchCityData();
					}
					this.setState({
						loginStatus: true,
						loading: false
					});
				},
				(reason) => {
					this.setState({ loginStatus: false });
				}
			);
		}
	}

	

	_handleChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	async _handleChangeCountry(e) {
		await this.setState({ country: e.target.value });
		this._fetchStateData();
	}

	async _handleChangeState(e) {
		await this.setState({ state: e.target.value });
		this._fetchCityData();
	}

	_handleChangeCity(e) {
		this.setState({ city: e.target.value });
	}

	_fetchCountryData = () => {
		APIService.fetchCountry().then(
			(units) => {
				// console.log(units);
				this.setState({
					countries: units
				});
			},
			(error) => this.setState({ internetConnected: false })
		);
	};
	_fetchStateData = () => {
		const { country } = this.state;
		APIService.fetchState(country).then(
			(units) => {
				this.setState({
					states: units
				});
			},
			(error) => this.setState({ internetConnected: false })
		);
	};

	_fetchCityData = () => {
		const { state } = this.state;
		APIService.fetchCity(state).then(
			(units) => {
				this.setState({
					cities: units
				});
			},
			(error) => this.setState({ internetConnected: false })
		);
	};

	_handleChangeImage(e) {
		this.setState({ profile_image: e.target.files[0] });
		this.setState({ profile_change: true });


		var reader = new FileReader();
		var url = reader.readAsDataURL(e.target.files[0]);

		reader.onloadend = function (e) {
			this.setState({
				imgSrc: [reader.result]
			})
			}.bind(this);


	}


	_handleSubmitAdd(e) {
		e.preventDefault();
		this.setState({ loading: true });
		// const { firstName, lastName } = this.state;
		APIService.addUser(this.state).then(
			(unit) => {
				this.setState({
					success: true,
					loading: false,
					redirect: true,
					redirectPath: '/users',
					redirectData: {
						visible: true,
						alertStyle: 'success',
						alertIcon: 'fa fa-check mx-2',
						alertMessage: 'User added successfully.'
					}
				});
			},
			(error) => {
				alert(JSON.stringify(error, null, 2));
				this.setState({
					success: false,
					loading: false,
					visible: true,
					alertStyle: 'danger',
					alertIcon: 'fa fa-exclamation mx-2',
					alertMessage: error.errorMessage
				});
			}
		);
	}

	_handleSubmitUpdate(e) {
		e.preventDefault();
		// const { name } = this.state;
		const id = this.state.id;
		APIService.updateUser(id, this.state).then(
			(unit) => {
				this.setState({
					success: true,
					loading: false,
					redirect: true,
					redirectPath: '/users',
					redirectData: {
						visible: true,
						alertStyle: 'success',
						alertIcon: 'fa fa-check mx-2',
						alertMessage: 'User updated successfully.'
					}
				});
			},

			(error) => {
				// alert(JSON.stringify(error, null, 2));
				this.setState({
					success: false,
					loading: false,
					visible: true,
					alertStyle: 'danger',
					alertIcon: 'fa fa-exclamation mx-2',
					alertMessage: error.errorMessage
				});
			}
		);
	}

	dismiss() {
		this.setState({ visible: false });
	}

	renderCountry = (Obj, i) => {
		return (
			<option value={Obj.id} key={i} selected={this.state.country == Obj.id}>
				{Obj.name}
			</option>
		);
	};

	renderState = (Obj, i) => {
		return (
			<option value={Obj.id} key={i} selected={this.state.state == Obj.id}>
				{Obj.name}
			</option>
		);
	};
	renderCity = (Obj, i) => {
		return (
			<option value={Obj.id} key={i} selected={this.state.city == Obj.id}>
				{Obj.name}
			</option>
		);
	};
	

	render() {
		const { loginStatus, loading, internetConnected,first_name,last_name,address_1,email,phone,zip_code,profile_change,profile_image } = this.state;

		const isEnabled = first_name.length > 0 && last_name.length > 0 && address_1.length > 0 && email.length > 0 && phone.length > 0 && zip_code.length > 0 && profile_image;

		

		this.renderCountry = this.renderCountry.bind(this);
		this.renderState = this.renderState.bind(this);
		this.renderCity = this.renderCity.bind(this);
		const selectCountry = this.state.countries.length > 0 && this.state.countries.map(this.renderCountry);
		const selectState = this.state.states.length > 0 && this.state.states.map(this.renderState);
		const selectCity = this.state.cities.length > 0 && this.state.cities.map(this.renderCity);
		if (this.state.redirect) {
			return (
				<Redirect
					to={{
						pathname: this.state.redirectPath,
						state: this.state.redirectData
					}}
				/>
			);
		}
		if (!internetConnected) {
			return <div>No Internet Connection.</div>;
		}
		if (loading) {
			return <Loader />;
		}
		if (loginStatus === undefined) {
			return <Loader />;
		} else if (loginStatus) {
			return (
				<div>
					<Container fluid className="px-0">
						<Alert
							theme={this.state.alertStyle || 'primary'}
							dismissible={this.dismiss}
							open={this.state.visible}
							className="mb-0"
						>
							<i className={this.state.alertIcon} /> {this.state.alertMessage}
						</Alert>
					</Container>
					<Container fluid className="main-content-container px-4">
						<MainTitle title="Customers" />
						<Row>
							<Col>
								<Card small className="mb-4">
									<ContentHeader title={this.state.update ? 'Edit Customer' : 'Add Customer'}>
										<Button
											outline
											theme="primary"
											className="mb-2 mr-1"
											onClick={() => {
												if (
													window.confirm(
														'All your changes will be lost. Do you still want continue?'
													)
												) {
													this.setState({
														redirect: true,
														redirectPath: '/users'
													});
												}
											}}
										>
											Back
										</Button>
									</ContentHeader>
									<CardBody className="p-0 pb-3">
										<Form onSubmit={this.state.update ? this._handleSubmitUpdate : this._handleSubmitAdd}>
											<Row form>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="role_type">User Type</label>
													<FormSelect id="role_type" name="role_type" onChange={this._handleChange}>
														<option value="1" selected={this.state.role_type == 1}>Customer</option>
														<option value="2" selected={this.state.role_type == 2}>Service Provider</option>
														<option value="3" selected={this.state.role_type == 3}>Both</option>
													</FormSelect>
												</Col>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="status">Status</label>
													<FormSelect id="status" name="status" onChange={this._handleChange}>
														<option value="1" selected={this.state.status == 1}>Active</option>
														<option value="0"selected={this.state.status == 0}>In Active</option>
													</FormSelect>
												</Col>
													<Col md={{ size: 4, order: 4 }} className="form-group p-3">
														<label htmlFor="fepassword">Password</label>
														<InputGroup>
															<FormInput
																id="fepassword"
																type="text"
																placeholder="Password"
																name="password"
																onChange={(e) => {
																	this.setState({ password: e.target.value });
																}}
																value={this.state.password}
																required
															/>
															<InputGroupAddon type="append">
																<Button type="button" onClick={(e)=>{
																		this.setState({password:Math.random().toString(36).substring(2),password_change:true})
																}}>Generate Password</Button>
															</InputGroupAddon>
														</InputGroup>
													</Col>
											</Row>

											<CardHeader>{this.state.form_step == 1 ? 'User Information' : this.state.form_step == 2 ? 'Payment Information' : null}</CardHeader>
											{this.state.form_step == 1 ? (
												<div>
											<Row form>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feFirstName">First Name</label>
													<FormInput
														id="feFirstName"
														type="text"
														placeholder="First Name"
														name="first_name"
														onChange={this._handleChange}
														value={this.state.first_name}
														required
													/>
												</Col>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feMiddleName">Middle Name</label>
													<FormInput
														id="feMiddleName"
														placeholder="Middle Name"
														type="text"
														name="middle_name"
														onChange={this._handleChange}
														value={this.state.middle_name}
														
													/>
												</Col>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feLastName">Last Name</label>
													<FormInput
														id="feLastName"
														placeholder="Last Name"
														type="text"
														name="last_name"
														onChange={this._handleChange}
														value={this.state.last_name}
														required
													/>
												</Col>
											</Row>
											<Row form>
												<Col md={{ size: 6, order: 6 }} className="form-group p-3">
													<label htmlFor="feEmail">Email</label>
													<FormInput
														id="feEmail"
														type="email"
														placeholder="Email"
														name="email"
														onChange={this._handleChange}
														value={this.state.email}
														required
													/>
												</Col>
												<Col md={{ size: 6, order: 6 }} className="form-group p-3">
													<label htmlFor="feMobile">Mobile</label>
													<FormInput
														id="feMobile"
														type="text"
														placeholder="Mobile"
														name="phone"
														onChange={this._handleChange}
														value={this.state.phone}
														required
													/>
												</Col>
											</Row>
											<Row form>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feAddress">Address 1</label>
													<FormInput
														id="feAddress"
														type="text"
														placeholder="Address Line 1"
														name="address_1"
														onChange={this._handleChange}
														value={this.state.address_1}
														required
													/>
												</Col>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feAddress2">Address 2</label>
													<FormInput
														id="feAddress2"
														type="text"
														placeholder="Address Line 2"
														name="address_2"
														onChange={this._handleChange}
														value={this.state.address_2}
													/>
												</Col>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feCountry">Country</label>
													<FormSelect id="feCountry" name="country_id" onChange={this._handleChangeCountry} required>
														<option>Choose</option>
														{selectCountry}
													</FormSelect>
												</Col>
											</Row>
											<Row form>
												<Col md={{ size: 4, order:4 }} className="form-group p-3">
													<label htmlFor="feState">State</label>
													<FormSelect id="feState" onChange={this._handleChangeState} required>
														<option>Choose</option>
														{selectState}
													</FormSelect>
												</Col>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feCity">City</label>
													<FormSelect id="feCity" onChange={this._handleChangeCity} required>
														<option>Choose</option>
														{selectCity}
													</FormSelect>
												</Col>
												<Col md={{ size: 4, order: 4 }} className="form-group p-3">
													<label htmlFor="feZip">Zip Code</label>
													<FormInput
														id="feZip"
														type="text"
														placeholder="Zip Code"
														name="zip_code"
														onChange={this._handleChange}
														value={this.state.zip_code}
														required
													/>
												</Col>
											</Row>
											<Row form>
												<Col md={{ size: 4, order: 4 }} className="form-group">
													<label>Profile Image</label>
													<div className="custom-file mb-3">
														<input
															type="file"
															className="custom-file-input"
															id="profile_image"
															name="profile_image"
															onChange={this._handleChangeImage}
															required
														/>
														<label className="custom-file-label" htmlFor="profile_image">
															Choose Profile Image...
														</label>
													</div>
												</Col>
											</Row>
											<Col>
												<img src={this.state.imgSrc} width="10%" id="profile_img"/>
											</Col>
											</div>
										) : 
										(
											<div>
												<Row form>
													<Col md={{ size: 6, order: 6 }} className="form-group p-3">
														<label htmlFor="bank_name">Bank Name</label>
														<FormInput
															id="bank_name"
															type="text"
															placeholder="Bank Name"
															name="bank_name"
															onChange={this._handleChange}
															value={this.state.bank_name}
															
														/>
													</Col>
													<Col md={{ size: 6, order: 6 }} className="form-group p-3">
														<label htmlFor="account_name">Name on the account</label>
														<FormInput
															id="account_name"
															type="text"
															placeholder="Account Name"
															name="account_name"
															onChange={this._handleChange}
															value={this.state.account_name}
															
														/>
													</Col>
												</Row>
												
												<Row form>
													<Col md={{ size: 6, order: 6 }} className="form-group p-3">
														<label htmlFor="account_number">Account Number</label>
														<FormInput
															id="account_number"
															type="text"
															placeholder="Account Name"
															name="account_number"
															onChange={this._handleChange}
															value={this.state.account_number}
															
														/>
													</Col>
													<Col md={{ size: 6, order: 6 }} className="form-group p-3">
														<label htmlFor="routing_number">Routing Number</label>
														<FormInput
															id="routing_number"
															type="text"
															placeholder="Routing Number"
															name="routing_number"
															onChange={this._handleChange}
															value={this.state.routing_number}
															
														/>
													</Col>
												</Row>

												<Row form>
													<Col md={{ size: 6, order: 6 }} className="form-group p-3">
														<label htmlFor="account_nick">Account Nick Name</label>
														<FormInput
															id="account_nick"
															type="text"
															placeholder="Account Nick Name"
															name="account_nick"
															onChange={this._handleChange}
															value={this.state.account_nick}
															
														/>
													</Col>
													<Col md={{ size: 6, order: 6 }} className="form-group p-3">
														<label htmlFor="account_type">Account Type</label>
														<FormSelect id="account_type" name="account_type" onChange={this._handleChange}>
															<option value="1" selected>Savings</option>
															<option value="2">Checking</option>
														</FormSelect>
													</Col>
												</Row>
												
											</div>
										)
										}
											<div style={{float: 'right',marginRight:'10px'}}>

												{this.state.form_step == 2 ? 
												(
													<div>
														<Button onClick={(e)=>{
															this.setState({form_step:1})
														}} style={{marginRight:'10px'}}>Back</Button>

														<Button theme="success" type="submit">Submit</Button>
													</div>
												)
												: null}

											{this.state.form_step == 1 ? 
												(

												<Button disabled={!isEnabled} onClick={(e)=>{
													this.setState({form_step:2})
												}}>Next</Button>

												)
												: null}

											</div>
										<div style={{clear: 'both'}}></div>
										</Form>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
				);
		} else {
			return <Redirect to="/login" />;
		}
	}
}

export default AddEditUser;
