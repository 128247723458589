import React from "react";
import Calender from "../ServiceRequest/Calender";
import ContentHeader from "../../components/common/ContentHeader";
import MainTitle from "../../components/common/MainTitle";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

const CouponManagement = (props) => {
   
  return (
    <div>
      <Container fluid className="main-content-container">
        <MainTitle title="Coupon Management" />
        <Row>
          <Col lg="12" className="mb-4">
            <ContentHeader>
              <Button
                onClick={() => {
                  props.history.push("/coupon-managemnet");
                }}
                outline
                theme="primary"
                className="mb-2 mr-2"
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  props.history.push("/coupon-managemnet");
                }}
                outline
                theme="primary"
                className="mb-2 mr-2 ml-2"
              >
                Update
              </Button>
              <Button
                onClick={() => {
                  props.history.push("/coupon-managemnet");
                }}
                outline
                theme="primary"
                className="mb-2 mr-2 ml-2"
              >
                Cancel
              </Button>
            </ContentHeader>
            <ListGroupItem>
              <Row>
             
                <Col md={{ size: 3, order: 2 }} className="mr-5">
                <label>Coupon Discount %</label>
                <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="Start Value %"
                    name="customerId"
                  />
                  
                   <FormInput
                    id="feCustId"
                    type="text"
                    placeholder="End Value %"
                    name="customerId"
                  />
                </Col>

                <Col md={{ size: 4, order: 4 }} className="form-group p-3 ml-5">
                  <label>Coupon Validity Start Date Range</label>
                  <Calender />
                </Col>
                <Col md={{ size: 4, order: 4 }} className="form-group p-3">
                  <label>Coupon Validity End Date Range</label>
                  <Calender />
                </Col>
                <Col md={{ size: 10, order: 4 }} className="form-group p-3">
                  <label>Coupon Validity Date Range</label>
                  <Calender />
                </Col>

                <Col md={{ size: 2, order: 4 }} className="form-group p-3">
                  <Button outline theme="primary" className="mt -4 mb-2 ml-4">
                    Apply Filter
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          </Col>
        </Row>
      </Container>
      <Container fluid className="main-content-container px-4">
        <Row>
          <Col lg="12" md={{ size: 4, order: 4 }} className="mb-6">
            <h4>
          
              <b>Coupon List </b>
            </h4>
          </Col>
          <Col lg="12" className="mb-6"></Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <MaterialTable
                  title=""
                  columns={[
                    { title: "ID", field: "id" },
                    { title: "Coupon Name", field: "srId" },
                    {
                      title: "Coupon Code",
                      field: "srCreationDate",
                    },
                    { title: "Coupon Description", field: "srScheduledDate" },
                    { title: "Coupon Discount (%)", field: "srScheduledDate" },
                    { title: "Coupon Value($)", field: "srScheduledDate" },
                    { title: "Validity Start Date", field: "user.first_name" },
                    { title: "Validity End Date", field: "user.last_name" },
                    { title: "Coupon Limit", field: "user.last_name" },
                    {
                      title: "No. of Times Used",
                      field: "user.last_name",
                    },
                  ]}
                  data={[
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 1987,
                      birthCity: 63,
                    },
                    {
                      name: "",
                      surname: "Baran",
                      birthYear: 2017,
                      birthCity: 34,
                    },
                  ]}
                  options={{
                    search: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportCsv: (columns, data) => {},
                  }}
                  actions={[
                    {
                      icon: "check",
                      tooltip: "Coupon Details",
                    },

                    {
                      icon: "edit",
                      tooltip: "Edit Coupon",
                      onClick: (event, data) => {
                        console.log(data);
                        console.log(event);
                        props.history.push("/edit-quote");
                      },
                    },

                    {
                      icon: "delete",
                      tooltip: "Delete Coupon",
                    },
                  ]}
                />
         <Button
                onClick={() => {
                  props.history.push("");
                }}
                outline
                theme="primary"
                className=" mt-2 mb-2 mr-2 ml-4"
              >
                Add Coupons
              </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
       
      </Container>
    </div>
  );
};
export default CouponManagement;
